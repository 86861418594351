import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, InlineList, SixteenNineAspectRatioPlaceholder } from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import { Header, Label, FormFieldContainer, FormFieldSet, FormField } from '../../components/FormElements';
import RichTextEditor from '../../containers/RichTextEditor';
import { PrimaryButton } from '../../components/Buttons';
import RemoveContentSection from '../../components/RemoveContentSection';
import ImageCrop, { ImageCropUtils } from '../../components/ImageCrop';
import alertService from '../../services/AlertService';
import windowService from '../../services/windowService';
import curriculumService, { getAvailableThroughMessage } from '../../services/curriculumService';
import sessionTimelineService from '../../services/sessionTimelineService';
import { useUser } from '../../authentication';
import useCurriculum from '../../hooks/useCurriculum';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ManageSession() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true, isError: false });
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId } = useParams();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: 'Session Calendar',
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`,
    },
    {
      name: 'Session',
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session/${sessionId}/timeline/${timelineId}`,
    },
    { name: 'Edit' },
  ];

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getSessionById(sessionId, true),
    ])
      .then(([curriculum, session]) => {
        setData({
          curriculum: curriculum,
          session: {
            ...session,
            description: session.description
              ? EditorState.createWithContent(stateFromHTML(session.description))
              : EditorState.createEmpty(),
          },
          isLoading: false,
          isError: false,
        });
      })
      .catch(error => {
        console.error(error);
        setData({ ...data, isLoading: false, isError: true });
      });
  }, []);

  const uploadSessionImage = image =>
    new Promise((resolve, reject) => {
      const bannerFilename = ImageCropUtils.generateFilename();
      ImageCropUtils.convertCroppedImageToBlob(image)
        .then(blob => curriculumService.uploadCurriculumImage(bannerFilename, blob))
        .then(() => resolve(bannerFilename))
        .catch(reject);
    });

  const saveSession = (values, { setSubmitting }) => {
    setSubmitting(true);

    const updateCalls = [];

    if (data.session.name !== values.name)
      updateCalls.push(curriculumService.updateSessionName(data.session.issueId, sessionId, values.name));

    if (data.session.description !== values.description) {
      const html = stateToHTML(values.description.getCurrentContent());
      updateCalls.push(curriculumService.updateSessionDescription(data.session.issueId, sessionId, html));
    }

    if (data.session.image !== values.image) {
      if (ImageCropUtils.isCroppedImage(values.image)) {
        updateCalls.push(
          uploadSessionImage(values.image).then(image =>
            curriculumService.updateSessionImage(data.session.issueId, sessionId, image)
          )
        );
      } else if (!values.image) {
        updateCalls.push(curriculumService.updateSessionImage(data.session.issueId, sessionId, values.image));
      }
    }

    return Promise.all(updateCalls)
      .then(() => {
        alertService.showOnNextPage('Session Saved');
        windowService.redirectBack();
      })
      .catch(error => {
        console.error(error);
        setSubmitting(false);
        alertService.show(
          'Oops, we’re unable to save changes at this time. We’re sorry for the inconvenience. Please try again later.',
          'error'
        );
      });
  };

  const eraseSessionData = session => {
    return Promise.all([
      curriculumService.updateSessionName(session.issueId, session.sessionId, ''),
      curriculumService.updateSessionDescription(session.issueId, session.sessionId, ''),
      curriculumService.updateSessionImage(session.issueId, session.sessionId, ''),
    ]);
  };

  const removeSession = () => {
    sessionTimelineService
      .deleteSessionTimeline(timelineId, user.userId)
      .then(() => eraseSessionData(data.session))
      .then(() => {
        alertService.showOnNextPage('Session Removed');
        windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`);
      })
      .catch(reason => {
        console.error(reason);
        alertService.showError();
        throw reason;
      });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="manageSessionView">
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <Header>Edit Session</Header>
            <InlineList content={'•'}>
              <li data-qa-hook="scheduledDate">{format(new Date(data.session.useDate), 'MMMM d, yyyy')}</li>
              <li data-qa-hook="availableThroughDate">
                {getAvailableThroughMessage(data.session, new Date(data.session.publishEndDate), 'MMMM do')}
              </li>
            </InlineList>
            <Formik
              initialValues={data.session}
              enableReinitialize={true}
              validationSchema={Yup.object({
                name: Yup.string().max(64, 'Name is too long').required('A name is required'),
              })}
              onSubmit={saveSession}
            >
              {({ isSubmitting, values, setValues, setFieldValue }) => (
                <Form id="session-information-form" style={{ margin: '32px 0' }}>
                  <FormFieldSet name={'Session Information'}>
                    <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24">
                      <div className="grid-col-span-4 grid-sm-col-span-4 grid-md-col-span-3">
                        <FormFieldContainer>
                          <Label>Poster</Label>
                          <SixteenNineAspectRatioPlaceholder actionable={!values.image}>
                            <ImageCrop
                              defaultImage={values.image}
                              onUpdate={image => setValues(prevValues => ({ ...prevValues, image: image }), false)}
                              buttonText="Upload Poster"
                              aspectRatio={16 / 9}
                            />
                          </SixteenNineAspectRatioPlaceholder>
                        </FormFieldContainer>
                      </div>
                      <div className="grid-col-span-4 grid-sm-col-span-4 grid-md-col-span-5">
                        <FormFieldContainer>
                          <FormField
                            data-qa-hook="manageSessionName"
                            label="Name"
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                          />
                          <Label>Description</Label>
                          <RichTextEditor
                            editorState={values.description}
                            setEditorState={editorState => setFieldValue('description', editorState)}
                            inlineStyles={[{ label: 'Bold', style: 'BOLD' }]}
                            blockTypes={[]}
                          />
                        </FormFieldContainer>
                      </div>
                    </div>
                    <PrimaryButton
                      data-qa-hook="saveSession"
                      className="pull-right"
                      type="submit"
                      disabled={isSubmitting}
                      operating={isSubmitting}
                    >
                      Save Session
                    </PrimaryButton>
                  </FormFieldSet>
                </Form>
              )}
            </Formik>
            <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-24">
              <div className="grid-col-span-4 grid-md-col-span-3"></div>
              <div className="grid-col-span-4 grid-md-col-span-5">
                <RemoveContentSection
                  type="Session"
                  modalSubtext={data.session.name}
                  modalContent={
                    <ErrorMessage>
                      All leader guide content and materials for this session will be completely deleted
                    </ErrorMessage>
                  }
                  onRemove={removeSession}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
