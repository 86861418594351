import { parseDate } from '../utils/dateUtils';

const monthFilter = index =>
  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][index];

const monthAbbrFilter = index =>
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][index];

const ymdToLocalMidnight = ymd => new Date(`${ymd}T00:00:00`);

const ymdFromDate = date => date.toISOString().split('T')[0];

const sessionDateFilter = session => ({
  ...session,
  availableStartDate: parseDate(session.publishStartDate),
  availableThroughDate: parseDate(session.publishEndDate),
  scheduledDate: parseDate(session.scheduledDate),
  recommendedDate: parseDate(session.recommendedDate),
});

const stripUTC = dateString => dateString.replace('[UTC]', '');

export { monthFilter, monthAbbrFilter, sessionDateFilter, ymdToLocalMidnight, ymdFromDate, stripUTC };
