import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import { InviteGroup, InviteRole, InviteSettings, InviteTraining } from '../models/inviteSettings';

type RoleWorkflowEntity = {
  _type: 'RoleView';
  roleId: { id: string };
  name: string;
};

type GroupWorkflowEntity = {
  _type: 'GroupView';
  groupId: { id: string };
  name: string;
};

type TrainingWorkflowEntity = {
  _type: 'Assign';
  id: string;
  trainingName: string;
  // The BE returns different structures for tasklists and workflows
  training: { id: { taskListId?: { id: string }; workflowId?: string } };
};

type WorkflowEntity = RoleWorkflowEntity | GroupWorkflowEntity | TrainingWorkflowEntity;

const transformRoleEntity = (entity: RoleWorkflowEntity): InviteRole => ({
  id: { id: entity.roleId.id },
  name: entity.name,
});

const transformGroupEntity = (entity: GroupWorkflowEntity): InviteGroup => ({
  id: { id: entity.groupId.id },
  name: entity.name,
});

const transformTrainingEntity = (entity: TrainingWorkflowEntity): InviteTraining => {
  return {
    id: entity.training.id.taskListId?.id || entity.training.id.workflowId || '',
    trainingId: entity.training.id.taskListId?.id || entity.training.id.workflowId || '',
    name: entity.trainingName,
    _type: entity.training.id.taskListId ? 'TaskList' : 'Workflow',
    taskListId: entity.training.id.taskListId,
    workflowId: entity.training.id.workflowId ? { id: entity.training.id.workflowId } : undefined,
    active: true,
  };
};
export const getInviteSettings: (settingsId: string) => Promise<InviteSettings> = settingsId =>
  cacheableRequest(`/v1/workflows/${settingsId}`).then((response: AxiosResponse<{ entities: WorkflowEntity[] }>) =>
    response.data.entities.reduce(
      (settings: InviteSettings, entity: WorkflowEntity) => {
        switch (entity._type) {
          case 'RoleView':
            return { ...settings, roles: [...settings.roles, transformRoleEntity(entity)] };
          case 'GroupView':
            return { ...settings, groups: [...settings.groups, transformGroupEntity(entity)] };
          case 'Assign':
            return { ...settings, trainings: [...settings.trainings, transformTrainingEntity(entity)] };
          default:
            return settings;
        }
      },
      { roles: [], licenses: [], groups: [], trainings: [] } as InviteSettings
    )
  );
