import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Shadow, Type } from '../StyleGuide';
import ErrorMessage from './ErrorMessage';

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  box-shadow: ${Shadow.regular};
  text-align: center;
  margin: 0 auto;
  border-radius: ${Border.radius};
  width: 100%;
  max-width: 600px;
  max-height: calc(100vh - ${Grid._6}); /* viewport height - 2x padding */
  box-shadow: ${Shadow.regular};
`;

export const ModalHeader = styled.div`
  color: #fff;
  background-color: ${Color.Gray._90};
  padding: 0 ${Grid._5};
  border-radius: ${Border.radius} ${Border.radius} 0 0;
  text-align: left;

  h3 {
    display: flex;
    align-items: center;
    color: inherit;
    text-shadow: ${Type.Shadow.dark};
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    margin: ${Grid._4} 0;
  }
`;

export const ModalBody = styled.div`
  text-align: left;
  padding: ${Grid._4};
  color: ${Type.Color.medium};
  overflow-y: ${props => (props.preventScroll ? 'visible' : 'auto')};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5};
  }

  h3 {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    font-size: ${Type.Scale._4};
    margin: 0;
  }
  h4 {
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.normal};
    margin-top: 0;
  }
  .modal-component-subtext {
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
  }
  .modal-component-state-message {
    font-size: ${Type.Scale._1};
    padding-left: ${Grid._4};
    position: relative;
    margin: ${Grid._3} 0 0;
    .icon {
      font-size: ${Type.Scale._2};
      margin-right: ${Grid._1};
      color: ${Color.Red._50};
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  background: ${Color.Gray._05};
  padding: ${Grid._4} ${Grid._5};
  border-radius: 0 0 ${Border.radius} ${Border.radius};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }

  button {
    width: 100%;

    &:first-child {
      margin: 0 0 ${Grid._3} 0;
    }

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      width: inherit;
      &:first-child {
        margin: 0 ${Grid._4} 0 0;
      }
    }
  }
`;

const Background = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2147483004; /* bring it above Intercom */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Grid._4};
`;

const WarningListItem = styled(ErrorMessage)`
  justify-content: flex-start;
  background: none;
  border: none;
`;

export const ModalWarningList = ({ warnings }) =>
  warnings.map(warning => <WarningListItem key={warning}>{warning}</WarningListItem>);

export const ModalBackground = ({ children, dismissHandler, disableBackgroundDismiss = false }) => {
  const handleBackgroundClick = e => {
    if (['background'].indexOf(e.target.id) > -1) {
      dismissHandler();
    }
  };

  return createPortal(
    <Background id="background" onClickCapture={disableBackgroundDismiss ? () => {} : handleBackgroundClick}>
      {children}
    </Background>,
    document.body
  );
};

ModalBackground.propTypes = {
  dismissHandler: PropTypes.func.isRequired,
  disableBackgroundDismiss: PropTypes.bool,
  children: PropTypes.any,
};

export const ModalContainer = ({ children, ...props }) => (
  <ModalBackground {...props}>
    <Modal>{children}</Modal>
  </ModalBackground>
);

ModalContainer.propTypes = {
  children: PropTypes.any,
};
