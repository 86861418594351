import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import curriculumService from '../services/curriculumService';

const previewMessage = {
  title: 'This feature is unavailable during the preview',
  description: 'You will be able to use this feature when subscriptions are available beginning fall of 2024',
};

const Modal = ({ handleDismiss }) => {
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="modalHeader">Curriculum Preview</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="modalMessage">
        <p className="modal-component-subtext">{previewMessage.title}</p>
        <p>{previewMessage.description}</p>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton data-qa-hook="modalConfirm" onClick={handleDismiss}>
          Dismiss
        </MediumPrimaryButton>
      </ModalFooter>
    </ModalContainer>
  );
};

Modal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
};

const usePreviewRestriction = curriculumId => {
  const [PreviewRestrictionModal, setPreviewRestrictionModal] = useState(null);
  const isPreview = useMemo(() => curriculumService.isPreviewCurriculum(curriculumId), [curriculumId]);

  const restrictIfPreview = fn => () =>
    isPreview ? setPreviewRestrictionModal(<Modal handleDismiss={() => setPreviewRestrictionModal(null)} />) : fn();

  return { isPreview, restrictIfPreview, PreviewRestrictionModal, previewMessage };
};

export default usePreviewRestriction;
