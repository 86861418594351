import React from 'react';
import styled from 'styled-components';
import { fromUnixTime, format } from 'date-fns';
import windowService from '../../services/windowService';
import { Grid, Color, Type } from '../../StyleGuide';
import { SmallRoundedIconButton } from '../../components/Buttons';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import { InlineList } from '../../components/Layout';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { stopProp } from '../../utils/domUtils';
import { TrainingEntity, TrainingEntityType, LastModifiedDate } from '../models/training';
import { Content } from '../models/content';

type ContainerProps = {
  published: boolean;
};

export const ListItemContainer = styled(ListItemFlexRow)<ContainerProps>`
  gap: ${Grid._6};
  padding: ${Grid._4} ${Grid._3};
  align-items: center;

  ${props => !props.published && `background-color: ${Color.Gray._075};`}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._2};
  flex: 1;

  h4,
  p {
    margin: 0;
  }
`;

const Icon = styled.i`
  font-size: ${Type.Scale._5};
  color: ${Type.Color.medium};
`;

const TrainingTypeIconMap = {
  course: 'fas fa-tasks',
  pathway: 'fas fa-route',
  content: 'icon ion-ios-compose',
  curriculum: 'far fa-calendar-alt',
};
type Props<T> = {
  item: T;
  type: TrainingEntityType;
  onRemoveClick: (item: T) => void;
};

const formatLastModifiedDate = (lastModifiedDate: LastModifiedDate) =>
  format(
    typeof lastModifiedDate === 'number'
      ? fromUnixTime(lastModifiedDate / 1000)
      : typeof lastModifiedDate === 'string'
      ? new Date(lastModifiedDate)
      : lastModifiedDate,
    'MMM dd, yyyy'
  );

export function ListItem<T extends TrainingEntity>({ item, type, onRemoveClick }: Props<T>) {
  const { manageTrainingType, manageTrainingRoutePrefix } = useManageTrainingRoute();
  const lastModifiedDate = formatLastModifiedDate(item.lastModifiedDate);

  // TODO: Should this component handle the redirect logic? It seems like
  // it may be doing too much.
  const redirectToItem = (item: T, type: TrainingEntityType) => {
    const id = item.id;
    switch (type) {
      case 'course':
        return windowService.redirectTo(`${manageTrainingRoutePrefix}/courses/${id}`);
      case 'content':
        const content = item as unknown as Content;
        return content.type === 'Assessment'
          ? windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${id}`)
          : windowService.redirectTo(`/${manageTrainingType}/content/${id}`);
      case 'pathway':
        return windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${id}`);
    }
  };

  return (
    <ListItemContainer published={item.published} onClick={() => redirectToItem(item, type)}>
      <Icon className={`${TrainingTypeIconMap[type]} hidden-xs`}></Icon>

      <Content>
        <h4>{item.name}</h4>

        <InlineList>
          <li>Edited {lastModifiedDate}</li>
          {type === 'course' && <li>{item.category || 'No Category'}</li>}
          {type === 'content' && item.questions !== undefined && <li>{item.questions} Questions</li>}
        </InlineList>
      </Content>

      <SmallRoundedIconButton onClick={stopProp(onRemoveClick)} aria-label="remove">
        <i className="far fa-trash-alt fa-fw"></i>
      </SmallRoundedIconButton>
    </ListItemContainer>
  );
}
