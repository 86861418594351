import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormField, Label, FormGroupContainer, Radio, FormFieldContainer } from '../../components/FormElements';
import { Grid, Type, Breakpoints } from '../../StyleGuide';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Container, PageTitle } from '../../components/Layout';
import useCurriculum from '../../hooks/useCurriculum';
import { PrimaryButton, OutlineButton } from '../../components/Buttons';
import BibleStudyBanner from '../BibleStudyBanner';
import LoadingState from '../../components/LoadingState';
import { handleError } from '../../utils/apiUtils';
import ErrorMessage from '../../components/ErrorMessage';
import curriculumService, { CurriculumStorageLocations } from '../../services/curriculumService';
import FileUploader from '../../components/FileUploader';
import { useUser } from '../../authentication';
import { getFileTypeByMimeType } from '../../utils/fileUtils';
import alertService from '../../services/AlertService';
import windowService from '../../services/windowService';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto ${Grid._10} auto;
`;

const CollectionRadioContainer = styled.div`
  display: flex;
  gap: ${Grid._5};
  margin-bottom: ${Grid._4};
  align-items: center;
  & > label {
    display: flex;
    flex-direction: column;
    gap: ${Grid._2};
    & > h1 {
      font-size: ${Type.Scale._3};
      margin: 0px;
      font-weight: ${Type.Weight.semibold};
    }

    & > h2 {
      font-size: ${Type.Scale._2};
      margin: 0px;
      font-weight: ${Type.Weight.normal};
      color: ${Type.Color.medium};
    }
  }
`;

const CollectionRadio = styled(Radio)`
  width: 27px;
  height: 27px;
  min-width: 27px;

  &::before {
    width: 29px;
    height: 29px;
  }
  &::after {
    width: 29px;
    height: 29px;
  }
`;

const MaterialFormSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${Grid._8};
  gap: ${Grid._7};
  margin-bottom: ${Grid._10};
  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    flex-direction: column;
    gap: 0;
    margin-bottom: ${Grid._4};
  }

  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    margin-top: ${Grid._5};
    margin-bottom: 0;
  }
`;

const MaterialFormSection = styled.div`
  flex: ${props => (props.flex ? props.flex : '1')};
`;

const MaterialFormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Grid._6};
  @media (max-width: ${Breakpoints.screen_xs}) {
    flex-direction: column-reverse;
    gap: ${Grid._4};
    width: 100%;
  }
`;

// FileType: MaterialType
const FileTypeToMaterialType = Object.freeze({
  audio: 'audio',
  video: 'video',
  image: 'download',
  document: 'download',
  epub: 'download',
  zip: 'download',
});

const ManageCurriculumMaterial = () => {
  const { curriculumId, unitId, sessionId, materialId } = useParams();
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    session: {},
    unit: {},
    material: {},
    collections: [],
  });
  const { session, isLoading, isError, material, collections, sessionMaterials } = data;

  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const determineUploadLocation = ({ type }) => {
    const fileType = getFileTypeByMimeType(type);

    switch (fileType) {
      case 'audio':
      case 'video':
      case 'image':
        return CurriculumStorageLocations[fileType.toUpperCase()];
      default:
        return CurriculumStorageLocations.FILE;
    }
  };

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualUnit(unitId, curriculumId),
      curriculumService.getSession(unitId, sessionId),
      curriculumService.getCurriculumCollections(curriculumId),
      curriculumService.getSessionMaterials(sessionId),
    ])
      .then(([unitData, sessionData, collectionsData, sessionMaterialsData]) => {
        setData(prevData => ({
          ...prevData,
          unit: unitData,
          session: sessionData,
          collections: collectionsData,
          sessionMaterials: sessionMaterialsData,
        }));

        setCrumbs(prevCrumbs => [
          ...prevCrumbs,
          {
            name: `${unitData.name}`,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}`,
          },
          {
            name: `${sessionData.name}`,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/settings`,
          },
          {
            name: materialId ? `Edit Material` : 'New Material',
          },
        ]);

        if (materialId) {
          return curriculumService.getSessionMaterial(sessionId, materialId);
        }

        return null;
      })
      .then(materialData => {
        if (materialData) {
          setData(prevData => ({
            ...prevData,
            material: materialData,
          }));
        }

        setData(prevData => ({ ...prevData, isLoading: false, isError: false }));
      })
      .catch(error => {
        setData(prevData => ({ ...prevData, isError: true, isLoading: false }));
        handleError(error);
      });
  }, []);

  const getMaxMaterialOrder = () => {
    const maxOrder = sessionMaterials.reduce((acc, curr) => {
      if (curr.order > acc) {
        return curr.order;
      }
      return acc;
    }, 0);
    return maxOrder;
  };

  const uploadMaterialFile = (mediaId, materialType, fileName) =>
    materialType === 'video' || materialType === 'audio'
      ? curriculumService.uploadSessionMaterialMedia(mediaId, curriculumId, fileName, user.userId)
      : curriculumService.uploadSessionMaterialFile(mediaId, curriculumId, fileName, user.userId);

  const updateMaterial = ({ name, collections, package: pkg }) => {
    const updateMaterialPromise =
      material.package !== pkg || material.seriesIds !== collections
        ? curriculumService.updateSessionMaterial(sessionId, materialId, collections, pkg)
        : Promise.resolve();

    return updateMaterialPromise
      .then(() =>
        material.name !== name
          ? curriculumService.updateSessionMaterialName(sessionId, materialId, name)
          : Promise.resolve()
      )
      .then(() => {
        alertService.showOnNextPage('Material Saved');
        windowService.redirectTo(
          `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/settings`
        );
      })
      .catch(error => handleError(error));
  };

  const handleSubmit = (values, { setFieldError }) => {
    const { name, collections, file, package: pkg } = values;

    if (materialId) return updateMaterial(values);

    const materialType = FileTypeToMaterialType[getFileTypeByMimeType(file.type)];
    if (!materialType) {
      setFieldError('file', 'File type is invalid');
      console.error('Unsupported material type from file type'); // eslint-disable-line no-console
      return Promise.reject();
    }

    const { id: mediaId, name: fileName } = file;
    const nextOrder = getMaxMaterialOrder() + 1;

    return curriculumService
      .createSessionMaterial(sessionId, {
        materialType,
        mediaId,
        name,
        order: nextOrder,
        package: pkg,
        seriesIds: collections,
      })
      .then(() => uploadMaterialFile(mediaId, materialType, fileName))
      .then(() => {
        alertService.showOnNextPage('Material Added');
        windowService.redirectTo(
          `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/settings`
        );
      })
      .catch(error => handleError(error));
  };

  const handleCollectionToggle = (collectionId, setFieldValue, values) => {
    if (values.collections.includes(collectionId)) {
      setFieldValue(
        'collections',
        values.collections.filter(id => id !== collectionId)
      );
    } else {
      setFieldValue('collections', [...values.collections, collectionId]);
    }
  };

  const getInitialFormValue = material => {
    return material
      ? {
          ...material,
          file: '',
          collections: material.seriesIds,
        }
      : {
          name: '',
          package: 'Basic',
          file: '',
          collections: [],
        };
  };

  const handleCancel = () => {
    windowService.redirectTo(
      `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/settings`
    );
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
      <BibleStudyBanner />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <PageContainer>
          <PageTitle>
            <h1>{session.name}</h1>
          </PageTitle>

          <Formik
            initialValues={getInitialFormValue(materialId && material)}
            validationSchema={Yup.object({
              name: Yup.string().max(64, 'Name is too long').required('A name is required'),
              file: Yup.mixed().when('materialId', {
                is: materialId => Boolean(!materialId),
                then: Yup.mixed().required('A file is required'),
                otherwise: Yup.mixed(),
              }),
              collections: Yup.array().min(1, 'At least one collection is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting, errors, submitCount }) => {
              return (
                <Form>
                  <MaterialFormSectionWrapper>
                    <MaterialFormSection flex={7}>
                      <FormField
                        label="Name"
                        data-qa-hook="materialName"
                        name="name"
                        placeholder="Enter Material Name"
                        onChange={e => setFieldValue('name', e.target.value)}
                      />
                      <FormFieldContainer>
                        <Label>Plans</Label>
                        <FormGroupContainer>
                          <p>Select which plans will have access to this material</p>
                          <div className="controls">
                            <Radio
                              id="material-package-availability--basic"
                              data-qa-hook="materialPackageAvailabilityPlusBasic"
                              checked={values.package === 'Basic'}
                              onChange={() => setFieldValue('package', 'Basic')}
                            />
                            <label htmlFor="material-package-availability--basic">All Plans</label>
                            <Radio
                              id="material-package-availability--plus"
                              data-qa-hook="materialPackageAvailabilityPlus"
                              checked={values.package === 'Plus'}
                              onChange={() => setFieldValue('package', 'Plus')}
                            />
                            <label htmlFor="material-plan-availability--plus">Plus Only</label>
                          </div>
                        </FormGroupContainer>
                      </FormFieldContainer>
                      {!materialId && (
                        <FormFieldContainer>
                          <Label>Upload File</Label>
                          <FileUploader
                            userId={user.userId}
                            orgId={user.lastSelectedAccount}
                            onFileUploaded={file => setFieldValue('file', file)}
                            onFileRemoved={file => setFieldValue('file', file)}
                            uploadLocation={determineUploadLocation}
                          />
                          {submitCount > 0 && errors.file && <ErrorMessage>{errors.file}</ErrorMessage>}
                        </FormFieldContainer>
                      )}
                    </MaterialFormSection>
                    <MaterialFormSection flex={6}>
                      <FormFieldContainer>
                        <Label>Collections</Label>
                        {collections.map(({ name, package: packageName, collectionId }) => (
                          <CollectionRadioContainer key={collectionId}>
                            <CollectionRadio
                              id={`materialCollectionRadio ${name}`}
                              data-qa-hook={`materialCollectionRadio`}
                              checked={values?.collections && values.collections.includes(collectionId)}
                              onClick={() => handleCollectionToggle(collectionId, setFieldValue, values)}
                              onChange={() => {}}
                            />
                            <label htmlFor="material-collection--babies-and-toddler">
                              <h1>{name}</h1>
                              <h2>{packageName === 'Basic' ? 'All Plans' : 'Plus Only'}</h2>
                            </label>
                          </CollectionRadioContainer>
                        ))}
                        {submitCount > 0 && errors.collections && <ErrorMessage>{errors.collections}</ErrorMessage>}
                      </FormFieldContainer>
                    </MaterialFormSection>
                  </MaterialFormSectionWrapper>
                  <MaterialFormButtonContainer>
                    <OutlineButton type="button" onClick={() => handleCancel()}>
                      Cancel
                    </OutlineButton>
                    <PrimaryButton
                      type="submit"
                      disabled={isSubmitting}
                      operating={isSubmitting}
                      data-qa-hook="materialSave"
                    >
                      {`${materialId ? 'Save' : 'Add'} Material`}
                    </PrimaryButton>
                  </MaterialFormButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </PageContainer>
      )}
    </>
  );
};

export default ManageCurriculumMaterial;
