import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { Radio, RadioListWithDescription } from '../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../components/ModalElements';
import windowService from '../services/windowService';

const AdvancedInvitesAddModal = ({ handleDismiss, orgId }) => {
  const handleSubmit = values => {
    const urlCode = values.type === 'sendEmails' ? 'email' : 'code';
    windowService.redirectTo(`#/invite-settings/${orgId}/${urlCode}`);
  };

  const invitationTypes = () =>
    Object.freeze({
      sendEmails: {
        text: 'Send Emails',
        iconClass: 'fas fa-paper-plane',
        description: 'Send a customized email to a defined list of recipients',
      },
      easyLink: {
        text: 'Easy Link',
        iconClass: 'fas fa-link',
        description: 'Create a URL that can be shared with anyone you want',
      },
    });

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Add Advanced Invite</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          type: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange }) => (
          <Form>
            <ModalBody>
              <h3>What type of invitation do you want to add?</h3>
              <RadioListWithDescription>
                {Object.entries(invitationTypes()).map(([type, { text, iconClass, description }]) => (
                  <li key={type}>
                    <label>
                      <Radio name="type" value={type} onChange={handleChange} />
                      <i className={iconClass}></i> {text}
                    </label>
                    <p>{description}</p>
                  </li>
                ))}
              </RadioListWithDescription>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton type="submit" operating={isSubmitting} data-qa-hook="addAdvancedInviteButton">
                Add Advanced Invite
              </MediumPrimaryButton>
              <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

AdvancedInvitesAddModal.propTypes = {
  handleDismiss: PropTypes.func,
  orgId: PropTypes.string,
};

export default AdvancedInvitesAddModal;
