import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import eventBus from '../services/globalEventBus';

/*
  Allows for easier use of the event bus in React.

  If you want a single, long-running event listener for your component,
    set it as the initialSubscriber. It will be set once on component
    mount then unsubscribed upon component unmount.

  If you want to set a single or multiple listeners later in the
    component lifecycle, use the push function returned by the
    hook to push listeners which will be cleaned up when the
    component is unmounted.
*/
const useEventBusSubscription = (eventName, initialSubscriber) => {
  const [subscribers, setSubscribers] = useState(initialSubscriber ? [initialSubscriber] : []);

  const removeSubscription = sub => setSubscribers(subs => subs.filter(s => s !== sub));

  const pushSubscription = sub => {
    setSubscribers(subs => [...subs, sub]);
    return () => removeSubscription(sub);
  };

  useEffect(() => {
    return eventBus.subscribe(eventName, data => subscribers.forEach(sub => sub(data)));
  }, [subscribers]);

  return pushSubscription;
};

useEventBusSubscription.propTypes = {
  eventName: PropTypes.string.isRequired,
  initialSubscriber: PropTypes.func,
};

export default useEventBusSubscription;
