angular.module('lwNamb').factory(
  'videoService',

  [
    '$rootScope',
    'api',
    'qt',
    'CacheFactory',
    'commandSubmissionService',
    function($rootScope, api, qt, CacheFactory, commandSubmissionService) {
      var timeoutSeconds = 15,
        urlCache = CacheFactory('video-urls', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        }),
        videoCache = CacheFactory('video', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        });

      return {
        getVideo: function(videoId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (videoCache.get(videoId)) {
            deferred.resolve(videoCache.get(videoId));
          } else {
            getVideoAndCache(videoId).then(function(video) {
              deferred.resolve(video);
            });
          }

          return deferred.promise;
        },
        deleteVideo: function(video, orgId, userId) {
          return commandSubmissionService.submitAndWait(video.id, {
            id: video.id,
            deletedBy: orgId+"_"+userId,
            filename: video.id+"."+video.name.split('.')[1],
            initiatingUserId: userId
          }, 'DeleteVideo', 'VideoDeleted', 'VideoError');
        },
        getUrls: function(videoId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (urlCache.get(videoId)) {
            deferred.resolve(urlCache.get(videoId));
          } else {
            getVideoUrlsAndCache(videoId).then(function(videoUrls) {
              deferred.resolve(videoUrls);
            });
          }

          return deferred.promise;
        },
        getProgress: function(videoId) {
          var deferred = qt.defer();
          api.get('/v1/videos/progress/' + videoId).then(
            function(response) {
              var p = response.data.progress === undefined ? 0 : response.data.progress;
              deferred.resolve(p);
            },
            function() {
              deferred.resolve(0);
            }
          );
          return deferred.promise;
        },
        saveProgress: function(userId, videoId, progress) {
          return api.post('/v1/videos/progress', { userId: { id: userId }, videoId: videoId, progress: progress });
        },
        getPoster: function(videoId) {
          var deferred = qt.defer();
          api.get('/v1/videos/' + videoId + '/poster').then(
            function(response) {
              deferred.resolve(response.data.poster);
            },
            function() {
              deferred.resolve('');
            }
          );
          return deferred.promise;
        },
      };

      function getVideoAndCache(videoId) {
        return api.get('/v1/videos/' + videoId).then(function(response) {
          videoCache.put(videoId, response.data);
          return response.data;
        });
      }

      function getVideoUrlsAndCache(videoId) {
        return api.get('/v1/videos/' + videoId + '/urls').then(function(response) {
          urlCache.put(videoId, response.data);
          return response.data;
        });
      }
    },
  ]
);
