import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, PageTitle, Image } from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Label } from '../../components/FormElements';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import { Button, PrimaryButton } from '../../components/Buttons';
import { Select, TextFilterInput } from '../../components/FormElements';
import { Color, Grid, Transition, Type, Shadow, Border } from '../../StyleGuide';
import videoService from '../../services/VideoService';
import LoadingIndicator from '../../components/LoadingIndicator';
import AddSectionMediaModal from './AddSectionMediaModal';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';
import useModal from '../../hooks/useModal';
import { getFileType, isAudioExtension } from '../../utils/fileUtils';
import { handleError } from '../../utils/apiUtils';
import { useUser } from '../../authentication';
import useCurriculum from '../../hooks/useCurriculum';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const MediaTypes = Object.freeze({
  Image: 'image',
  Video: 'video',
  Audio: 'audio',
});

const TypeDropdown = styled.div`
  flex: 1 0 auto;
  min-width: 240px;
`;

const SearchContainer = styled.div`
  flex: 1 0 auto;
  min-width: 320px;
`;

const Buttons = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  grid-gap: ${Grid._5};
  margin-bottom: 2px;

  > button {
    flex: 1 0 0%;
    min-width: 180px;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${Grid._5};
  align-items: flex-end;
  margin-bottom: ${Grid._6};
`;

const MediaItems = styled.div`
  display: grid;
  grid-wrap: wrap;
  grid-column-gap: ${Grid._5};
  grid-row-gap: ${Grid._6};
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

const MediaImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  width: 100%;
  background: ${Color.Gray._20};
  margin-bottom: ${Grid._3};
  color: ${Type.Color.medium};

  ${Image} {
    height: 100%;
    width: 100%;
  }
`;

const MediaDetails = styled.div`
  word-wrap: anywhere;
`;

const MediaItemContainer = styled.div`
  min-width: 260px;
  cursor: pointer;

  ${MediaImageContainer} {
    border-radius: ${Border.radius};
    outline: 3px solid ${Color.Gray._20};
    transition: ${Transition.normal};

    ${props =>
      props.selected &&
      `outline-color: ${Color.Primary._50};
      box-shadow: ${Shadow.medium};`}
  }
`;

const MediaImage = ({ media }) => {
  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [src, setSrc] = useState();

  const onImageLoaded = () => setStatus({ isLoading: false, isError: false });

  useEffect(() => {
    if (media._type === 'video') {
      videoService
        .getPoster(media.id)
        .then(posterUrl => {
          if (!posterUrl) return Promise.reject('Invalid poster response');

          setSrc(posterUrl);
        })
        .catch(error => {
          console.error(error);
          setStatus({ isLoading: false, isError: true });
        });
    } else {
      setSrc(media.url.thumbnail);
    }
  }, []);

  return (
    <MediaImageContainer>
      {status.isError ? (
        <span>{media.name}</span>
      ) : (
        <>
          {status.isLoading && <LoadingIndicator />}
          {src && (
            <Image
              src={src}
              alt={media.name}
              onLoad={onImageLoaded}
              style={{ display: status.isLoading ? 'none' : 'initial' }}
            />
          )}
        </>
      )}
    </MediaImageContainer>
  );
};

MediaImage.propTypes = {
  media: PropTypes.object.isRequired,
};

const isAudioMedia = media => media._type === MediaTypes.Video && isAudioExtension(media.ext);

const AudioMediaImage = () => (
  <MediaImageContainer>
    <i className="fas fa-music" style={{ fontSize: Type.Scale._7 }}></i>
  </MediaImageContainer>
);

const MediaItem = ({ media, onClick, selected, hidden }) => (
  <MediaItemContainer
    data-qa-hook="mediaItem"
    onClick={onClick}
    selected={selected}
    style={{ display: hidden ? 'none' : 'initial' }}
  >
    {isAudioMedia(media) ? <AudioMediaImage /> : <MediaImage media={media} />}
    <MediaDetails data-qa-hook="mediaName">{media.name}</MediaDetails>
  </MediaItemContainer>
);

MediaItem.propTypes = {
  media: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  hidden: PropTypes.bool,
};

const MediaList = ({ mediaItems, onAdd, onUploadClick, typeLimit = '', isSubmitting = false }) => {
  const [selected, setSelected] = useState();
  const [textFilter, setTextFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState(typeLimit);

  const matchesTextFilter = (mediaName, filter) => {
    if (!filter) return true;

    return mediaName.trim().toLowerCase().includes(filter.toLowerCase());
  };

  const matchesTypeFilter = (media, filter) => {
    switch (filter) {
      case MediaTypes.Image:
        return media._type === MediaTypes.Image;
      case MediaTypes.Video:
        return media._type === MediaTypes.Video && !isAudioMedia(media);
      case MediaTypes.Audio:
        return media._type === MediaTypes.Video && isAudioMedia(media);
      default:
        return true;
    }
  };

  const filteredOutMediaIds = useMemo(() => {
    if (!textFilter && !typeFilter) return [];

    return mediaItems
      .filter(
        media =>
          (!!textFilter && !matchesTextFilter(media.name, textFilter)) ||
          (!!typeFilter && !matchesTypeFilter(media, typeFilter))
      )
      .map(media => media.id);
  }, [mediaItems, textFilter, typeFilter]);

  const addMedia = () => {
    onAdd(selected);
  };

  return (
    <Container className="mb-48">
      <ControlsContainer>
        <TypeDropdown>
          <Label>Type</Label>
          <Select
            data-qa-hook="typeDropdown"
            defaultValue={typeFilter}
            disabled={!!typeLimit}
            onChange={e => setTypeFilter(e.target.value)}
          >
            <option value="">All Media</option>

            {Object.entries(MediaTypes).map(([displayName, type]) => (
              <option key={type} value={type}>
                {displayName}
              </option>
            ))}
          </Select>
        </TypeDropdown>

        <SearchContainer>
          <TextFilterInput
            placeholder="Search Media"
            value={textFilter}
            onChangeHandler={e => setTextFilter(e.target.value)}
            clearInput={() => setTextFilter('')}
          />
        </SearchContainer>

        <Buttons>
          <Button data-qa-hook="uploadMedia" onClick={onUploadClick}>
            <i className="fas fa-upload"></i> Upload Media
          </Button>

          <PrimaryButton
            data-qa-hook="addMedia"
            disabled={!selected || isSubmitting}
            onClick={addMedia}
            operating={isSubmitting}
          >
            Add
          </PrimaryButton>
        </Buttons>
      </ControlsContainer>

      <MediaItems data-qa-hook="mediaContainer">
        {mediaItems.map(media => (
          <MediaItem
            key={media.id}
            media={media}
            onClick={() => setSelected(media)}
            selected={selected === media}
            hidden={filteredOutMediaIds.includes(media.id)}
          />
        ))}
      </MediaItems>
    </Container>
  );
};

MediaList.propTypes = {
  mediaItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
  typeLimit: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

const AddSectionMedia = () => {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true, isError: false });
  const [typeParam] = useState(windowService.getParameterByName('type'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId, sectionId } = useParams();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: 'Session Calendar',
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`,
    },
    {
      name: 'Session',
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session/${sessionId}/timeline/${timelineId}`,
    },
    { name: 'Add Section Media' },
  ];

  const formatFileToMedia = file => {
    if (file instanceof File) {
      const fileType = getFileType(file.type);
      const storedFileType = fileType === MediaTypes.Audio ? MediaTypes.Video : fileType;
      return { id: file.id, ext: file.extension, name: file.name, _type: storedFileType };
    }

    const { url, ...media } = file;
    return media;
  };

  const onMediaSelected = file => {
    setIsSubmitting(true);
    sessionTimelineService
      .updateSectionMedia(timelineId, sectionId, formatFileToMedia(file), user.userId)
      .then(() => {
        windowService.redirectBack();
      })
      .catch(handleError)
      .finally(() => setIsSubmitting(false));
  };

  const onMediaSelectError = error => {
    handleError(error);
    dismissModal();
  };

  const [modal, openModal, dismissModal] = useModal((type, _, dismissModal) =>
    !type ? null : (
      <AddSectionMediaModal
        curriculumId={curriculumId}
        sectionType={typeParam}
        addMediaToSection={onMediaSelected}
        onError={onMediaSelectError}
        handleDismiss={dismissModal}
      />
    )
  );

  useEffect(() => {
    sessionTimelineService
      .getRecentlyUsedSectionMedia(curriculumId)
      .then(mediaItems => setData({ isLoading: false, isError: false, mediaItems }))
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <PageTitle data-qa-hook="addSectionMediaView" className="mt-40 mb-48">
            <h1>Add Section Media</h1>
            <p>Select existing media or upload new media</p>
          </PageTitle>
        )}
      </Container>
      {!data.isLoading && (
        <MediaList
          mediaItems={data.mediaItems}
          onAdd={onMediaSelected}
          onUploadClick={() => openModal(true)}
          typeLimit={typeParam}
          isSubmitting={isSubmitting}
        />
      )}
      {modal}
    </>
  );
};

export default AddSectionMedia;
