import React, { useContext, useState } from 'react';
import { PartialSearchResultItem } from '../models/Search';

type ContextType = {
  selectedTraining: PartialSearchResultItem[];
  setSelectedTraining: React.Dispatch<React.SetStateAction<PartialSearchResultItem[]>>;
  onAddSelectedTraining: (selectedTraining: PartialSearchResultItem[]) => void | Promise<void>;
};

const Context = React.createContext<ContextType>({
  selectedTraining: [],
  setSelectedTraining: () => {},
  onAddSelectedTraining: () => {},
});
Context.displayName = 'SelectTrainingContext';

export const useSelectTraining = () => {
  const { selectedTraining, setSelectedTraining, onAddSelectedTraining } = useContext(Context);

  const selectTraining = (training: PartialSearchResultItem) => {
    setSelectedTraining(prevSelectedTraining => [...prevSelectedTraining, training]);
  };

  const deselectTraining = (training: PartialSearchResultItem) => {
    setSelectedTraining(prevSelectedTraining => prevSelectedTraining.filter(selected => selected.id !== training.id));
  };

  const isTrainingSelected = (training: PartialSearchResultItem) =>
    selectedTraining.some(selected => selected.id === training.id);

  return { selectedTraining, selectTraining, deselectTraining, isTrainingSelected, onAddSelectedTraining };
};

type Props = {
  preselectedTraining?: PartialSearchResultItem[];
  onAddSelectedTraining: (selectedTraining: PartialSearchResultItem[]) => void | Promise<void>;
  children: React.ReactNode;
};

export const SelectTrainingProvider = ({ preselectedTraining = [], onAddSelectedTraining, children }: Props) => {
  const [selectedTraining, setSelectedTraining] = useState<PartialSearchResultItem[]>(preselectedTraining);

  return (
    <Context.Provider value={{ selectedTraining, setSelectedTraining, onAddSelectedTraining }}>
      {children}
    </Context.Provider>
  );
};
