import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Transition, Type, Shadow } from '../StyleGuide';
import LoadingIndicator from './LoadingIndicator';

//Buttons are full-width on mobile
export const ButtonStyles = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: ${Color.Gray._20};
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._3};
  font-weight: ${Type.Weight.medium};
  text-align: center;
  line-height: ${Type.Leading.tall};
  padding: 10px 20px;
  white-space: nowrap;
  border-radius: ${Border.radius};
  border: none;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.05);
  transition: ${Transition.fast};
  cursor: pointer;
  position: relative;
  i,
  span {
    margin-right: ${Grid._2};
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${Color.Gray._30};
  }
  outline: none;
  width: auto;
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Gray._50},
      0px 0px 0px 5px rgba(179, 179, 179, 0.3);
  }
  &:active {
    box-shadow: inset 0 2px 2px ${Color.Gray._30};
  }
`;

const CenteredContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

// TODO:DP: Figure out a better way to match loadingIndicatorColor with the button text color
export const Button = ({ loadingIndicatorColor = '#fff', ...props }) => (
  <ButtonStyles {...props}>
    {props.operating ? (
      <>
        <CenteredContainer>
          <LoadingIndicator width="25px" height="25px" strokeWidth="5" color={loadingIndicatorColor} hideTrack />
        </CenteredContainer>
        <span style={{ visibility: 'hidden' }}>{props.children}</span>
      </>
    ) : (
      props.children
    )}
  </ButtonStyles>
);

const PrimaryButtonContainer = styled(Button)`
  color: ${Color.white};
  background-color: ${Color.Primary._50};
  text-shadow: ${Type.Shadow.regular};
  &:hover {
    background-color: ${Color.Primary._60};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Primary._50},
      0px 0px 0px 5px rgba(76, 179, 124, 0.3);
  }
  &:active {
    box-shadow: inset 0 2px 2px ${Color.Primary._70};
  }
`;

export const PrimaryButton = props => (
  <PrimaryButtonContainer className="steps-primary-button" {...props}>
    {props.children}
  </PrimaryButtonContainer>
);

const DangerButtonContainer = styled(Button)`
  color: ${Color.white};
  background-color: ${Color.Red._50};
  box-shadow: inset 0 -3px 0 ${Color.Red._60};
  text-shadow: ${Type.Shadow.regular};
  &:hover {
    background-color: ${Color.Red._60};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Red._50},
      0px 0px 0px 5px rgba(206, 92, 91, 0.3);
  }
  &:active {
    box-shadow: inset 0 2px 2px ${Color.Red._70};
  }
`;

export const DangerButton = props => (
  <DangerButtonContainer className="steps-primary-button" {...props}>
    {props.children}
  </DangerButtonContainer>
);

export const SecondaryButton = styled(Button)`
  color: ${Type.Color.dark};
  background-color: ${Color.Blue._125};
  text-shadow: none;
  outline: none;
  &:hover {
    background-color: ${Color.Blue._30};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._50},
      0px 0px 0px 5px rgba(239, 243, 255, 0.5);
  }
  &:active {
    box-shadow: inset 0 2px 2px ${Color.Blue._60};
  }
`;

export const TertiaryButton = styled(Button)`
  color: ${Color.white};
  background-color: ${Color.Blue._50};
  text-shadow: ${Type.Shadow.dark};
  outline: none;
  &:hover {
    background-color: ${Color.Blue._60};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._60},
      0px 0px 0px 5px rgba(81, 144, 196, 0.3);
  }
  &:active {
    box-shadow: inset 0 2px 2px ${Color.Blue._60};
  }
`;

export const OutlineButton = styled(Button)`
  background: none;
  border: 1px solid ${Color.Gray._50};
  box-shadow: none;
  i {
    color: ${Type.Color.medium};
  }
  &:hover {
    background-color: ${Color.Gray._10};
  }
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: ${Color.Gray._20};
  }
`;

export const PrimaryButtonLight = styled(Button)`
  color: ${Type.Color.dark};
  background-color: ${Color.white};
  border: 0;
  box-shadow: inset 0 -3px 0 ${Color.Gray._70};
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
`;

const FullWidthButtonContainer = styled(Button)`
  color: ${Type.Color.dark};
  background-color: ${Color.white};
  border: 1px solid ${Color.Gray._20};
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), ${Shadow.small};
  i {
    color: ${Type.Color.medium};
    transition: ${Transition.fast};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    width: 100%;
    &:hover {
      background-color: ${Color.Gray._10};
      border-color: ${Color.Primary._50};
      i {
        color: ${Color.Primary._50};
      }
    }
    &:active {
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const FullWidthButton = props => (
  <FullWidthButtonContainer className="step-alternate-button" {...props}>
    {props.children}
  </FullWidthButtonContainer>
);

export const ActionRowButton = styled.a`
  color: ${Type.Color.dark} !important;
  padding: 16px 20px;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.medium};
  background: ${Color.white};
  border: 1px solid ${Color.Gray._20};
  border-radius: ${Border.radius};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${Shadow.small};
  text-decoration: none;
  transition: ${Transition.fast};
  outline: none;
  height: 100%;
  &:not(.disabled) {
    i {
      margin-left: ${Grid._4};
    }
  }
  &:hover.disabled {
    cursor: default;
  }
  &:hover:not(.disabled) {
    cursor: pointer;
    background-color: ${Color.white};
    box-shadow: ${Shadow.medium};
  }
  &:focus:not(.disabled) {
    outline: none;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Gray._50},
      0px 0px 0px 5px rgba(179, 179, 179, 0.3);
  }
  &:active:not(.disabled) {
    box-shadow: inset 0 2px 2px ${Color.Gray._30}, ${Shadow.medium};
  }
`;

export const WhiteButton = styled(Button)`
  color: ${Type.Color.dark};
  background-color: ${Color.white};
  border: 1px solid ${Color.Gray._30};
  box-shadow: none;
  &:hover {
    background-color: ${Color.Gray._10};
  }
`;

export const SmallButton = styled(Button)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
`;

const SmallPrimaryButtonContainer = styled(PrimaryButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
`;

export const SmallPrimaryButton = props => (
  <SmallPrimaryButtonContainer className="steps-primary-button" {...props}>
    {props.children}
  </SmallPrimaryButtonContainer>
);

export const SmallDangerButton = styled(DangerButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const SmallTertiaryButton = styled(TertiaryButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const SmallPrimaryButtonLight = styled(PrimaryButtonLight)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const SmallFullWidthButton = styled(FullWidthButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const SmallOutlineButton = styled(OutlineButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const SmallWhiteButton = styled(WhiteButton)`
  padding: ${Grid._3};
  font-size: ${Type.Scale._1};
  max-width: 100%;
`;

export const RoundedButton = styled(WhiteButton)`
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.semibold};
  color: ${props => (props.active ? `${Type.Color.dark}` : `${Type.Color.medium}`)};
  background: none;
  border: 1px solid ${Color.Secondary._40};
  outline: none;
  border-color: ${props => (props.active ? `${Type.Color.dark}` : `${Color.Secondary._40}`)};
  border-radius: ${Grid._5};
  i {
    color: ${props => (props.active ? `${Type.Color.dark}` : `${Type.Color.light}`)};
  }
  span {
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.light};
  }
  &:disabled {
    color: ${Type.Color.dark};
    border-color: ${Type.Color.dark};
    cursor: default;
    opacity: 1;
  }
  &:hover {
    background-color: ${Color.Gray._10};
  }
  &:focus {
    box-shadow: ${Color.Gray._50} 0px 0px 0px 1px, rgb(179, 179, 179, 0.3) 0px 0px 0px 5px;
  }
`;
export const LinkStyleButton = styled(Button)`
  color: ${Type.Color.medium};
  background-color: transparent;
  padding: ${Grid._3};
  font-size: ${Type.Scale._2};
  box-shadow: none;
  text-decoration: underline;
  &:hover {
    color: ${Type.Color.medium};
    background-color: ${Color.Gray._10};
    cursor: pointer;
  }
  &:disabled {
    text-decoration: none;
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }
  i {
    margin: 0 0 0 ${Grid._2};
  }
`;

export const SortButton = ({ sortActive, direction, children, ...props }) => (
  <LinkStyleButton {...props}>
    {children}
    {sortActive && (direction === 'up' ? <i className="ion-arrow-up-b"></i> : <i className="ion-arrow-down-b"></i>)}
  </LinkStyleButton>
);

SortButton.propTypes = {
  sortActive: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  children: PropTypes.any,
};

export const MediumButton = styled(Button)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

const MediumPrimaryButtonContainer = styled(PrimaryButton)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

export const MediumPrimaryButton = props => (
  <MediumPrimaryButtonContainer className="steps-primary-button" {...props}>
    {props.children}
  </MediumPrimaryButtonContainer>
);

export const MediumSecondaryButton = styled(SecondaryButton)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

export const MediumDangerButton = styled(DangerButton)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

export const MediumOutlineButton = styled(OutlineButton)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

export const MediumWhiteButton = styled(WhiteButton)`
  padding: ${Grid._3} ${Grid._4};
  font-size: ${Type.Scale._2};
`;

export const RoundedIconButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${Type.Color.medium};
  font-size: ${Type.Scale._1};
  line-height: 300%;
  outline: none;
  padding: 0;

  background: none;
  border: none;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Color.Gray._20};
    border-radius: 50%;
    width: ${Grid._7};
    height: ${Grid._7};
    transition: ${Transition.fast};
    font-size: ${Type.Scale._4};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      width: 50px;
      height: 50px;
      font-size: ${Type.Scale._5};
    }
  }
  &.transparent {
    opacity: 0.8;
  }
  &[disabled][disabled] {
    /* 2x[disabled] for higher specificity */
    opacity: 0.2;
  }
  &:hover i {
    background: ${Color.Gray._30};
    cursor: pointer;
  }
  &:focus i {
    box-shadow: 0px 0px 0px 1px ${Color.Gray._50}, 0px 0px 0px 5px rgba(179, 179, 179, 0.3);
  }
  &:active i {
    box-shadow: inset 0 0 2px 7px rgba(0, 0, 0, 0.03);
  }
`;

export const SmallRoundedIconButton = styled(RoundedIconButton)`
  font-size: ${Type.Scale._05};
  i {
    width: ${props => props.width ?? Grid._6};
    height: ${props => props.height ?? Grid._6};
    font-size: ${Type.Scale._2};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      width: ${props => props.width ?? Grid._6};
      height: ${props => props.height ?? Grid._6};
      font-size: ${Type.Scale._2};
    }
  }
  &.up i {
    transform: rotate(180deg);
    transition: transform 150ms ease;
  }
  &.down i {
    transform: rotate(0deg);
    transition: transform 150ms ease;
  }
`;

export const SmallPrimaryRoundedIconButton = styled(SmallRoundedIconButton)`
  i {
    color: ${Color.white};
    background: ${Color.Primary._50};
    text-shadow: ${Type.Shadow.regular};
  }
  &:hover i {
    background-color: ${Color.Primary._60};
  }
  &:focus i {
    box-shadow: 0px 0px 0px 1px ${Color.Primary._50}, 0px 0px 0px 5px rgba(76, 179, 124, 0.3);
  }
  &:active i {
    box-shadow: inset 0 2px 7px rgba(76, 179, 124, 0.3);
  }
`;

export const SmallTertiaryRoundedIconButton = styled(SmallRoundedIconButton)`
  i {
    color: ${Color.white};
    background-color: ${Color.Blue._50};
    text-shadow: ${Type.Shadow.regular};
  }
  &:hover i {
    background-color: ${Color.Blue._60};
  }
  &:focus i {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._60},
      0px 0px 0px 5px rgba(81, 144, 196, 0.3);
  }
  &:active i {
    box-shadow: inset 0 2px 2px ${Color.Blue._60};
  }
`;

export const SmallRadioRoundedIconButton = styled(SmallRoundedIconButton)`
  color: ${Color.Gray._90};

  .fa-circle {
    font-size: 21px;
  }

  i {
    background: transparent;
    border: 1px solid ${Color.Gray._90};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    &:hover i {
      background-color: transparent;
    }
    &:focus i {
      box-shadow: 0px 0px 0px 5px rgba(76, 179, 124, 0.3);
    }
    &:active i {
      box-shadow: inset 0 2px 7px rgba(76, 179, 124, 0.3);
    }
  }
`;

export const SmallTransparentRoundedIconButton = styled(SmallRoundedIconButton).attrs(() => ({
  className: 'transparent',
}))``;

export const SmallToggleRoundedIconButton = props => (
  <SmallRoundedIconButton className={props.status ? 'up' : 'down'} {...props}>
    {props.children}
  </SmallRoundedIconButton>
);

export const TileButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  border: 2px solid transparent;
  border-radius: ${Border.radius};
  padding: 10px;
  background: ${Color.Blue._125};
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._2};
  cursor: pointer;
  transition: ${Transition.normal};
  outline: none;
  i {
    color: #5e6f9c;
    font-size: ${Type.Scale._4};
    margin-right: ${Grid._3};
  }
  &:hover {
    border: 2px solid #a2a8b9;
  }
  &:focus {
    box-shadow: 0px 0px 0px 1px ${Color.Secondary._50}, 0px 0px 0px 5px rgba(81, 144, 196, 0.3);
  }
  &:active {
    box-shadow: inset 0 0 2px 7px rgba(0, 0, 0, 0.03);
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5} ${Grid._4};
    margin-right: ${Grid._4};
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: ${Grid._4} 0;
  button:not(:last-child) {
    margin: ${Grid._4} 0 0 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    button:not(:last-child) {
      margin: 0 ${Grid._4} 0 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  button + button {
    margin-left: ${props => props.gap || Grid._3};
  }
`;

export const ImageButtonGroup = styled(ButtonGroup)`
  position: absolute;
  bottom: ${Grid._4};
  right: ${Grid._4};
  z-index: 99;
`;

export const ToggleButton = ({ ButtonComponent = MediumOutlineButton, isOn, children, ...props }) => (
  <ButtonComponent data-qa-hook="toggleButton" {...props}>
    <i className={isOn ? 'fas fa-circle color-complete' : 'far fa-circle'}></i> {children}
  </ButtonComponent>
);

ToggleButton.propTypes = {
  ButtonComponent: PropTypes.elementType,
  isOn: PropTypes.bool,
  children: PropTypes.node,
};

const DashedBackgroundButtonContainerStyles = styled.div`
    display: flex;
    justify-content: center;

    > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        bottom: 0;
        right: 0;
        border-top: 2px dashed ${Color.Gray._30};
        z-index: 1;
      }

      button {
        z-index: 2;
      }
    }
  }
`;

export const DashedBackgroundButtonContainer = ({ children, ...props }) => (
  <DashedBackgroundButtonContainerStyles {...props}>
    <div>{children}</div>
  </DashedBackgroundButtonContainerStyles>
);

DashedBackgroundButtonContainer.propTypes = {
  children: PropTypes.node,
};
