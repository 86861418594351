import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorMessage from '../components/ErrorMessage';
import { PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import { PartialSearchResultItem, SearchProvider, SelectTraining, SelectTrainingProvider } from '../search';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import { PageContainer } from './components/InviteElements';
import { useInviteSettings } from './hooks/useInviteSettings';
import {
  transformInviteTrainingToSearchResultItem,
  transformSearchResultItemToInviteTraining,
} from './models/inviteSettings';

type UrlParams = {
  inviteId: string;
  settingsId: string;
  orgId: string;
  type: string;
};

export const AddTrainingToInvite = () => {
  const user = useUser();
  const { inviteId, settingsId, orgId, type } = useParams<UrlParams>();
  const { inviteSettings, updateSetting, isLoading, error } = useInviteSettings(
    inviteId,
    settingsId,
    user?.userId || ''
  );

  const inviteSettingsPath = `#/invite-settings/${orgId}/${type}${inviteId ? `/${inviteId}` : ''}${
    settingsId ? `/${settingsId}` : ''
  }`;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: 'Invite Settings', route: inviteSettingsPath },
    { name: 'Add Training' },
  ];

  const redirectToInviteSettings = () => {
    windowService.redirectTo(inviteSettingsPath);
  };

  const addTraining = (selectedTraining: PartialSearchResultItem[]) => {
    const inviteTraining = selectedTraining.map(transformSearchResultItemToInviteTraining);
    updateSetting('trainings', inviteTraining).then(redirectToInviteSettings).catch(handleError);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      {isLoading ? (
        <LoadingState />
      ) : error ? (
        <ErrorMessage />
      ) : (
        <PageContainer>
          <PageTitle className="mb-40">
            <h1>Select Training</h1>
            <p>Find the training you would like to attach to your invite</p>
          </PageTitle>

          <SelectTrainingProvider
            preselectedTraining={inviteSettings.trainings.map(transformInviteTrainingToSearchResultItem)}
            onAddSelectedTraining={addTraining}
          >
            <SearchProvider>
              <SelectTraining />
            </SearchProvider>
          </SelectTrainingProvider>
        </PageContainer>
      )}
    </>
  );
};
