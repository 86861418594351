import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Color, Transition, Type, Shadow, Breakpoints } from '../StyleGuide';

export const ContainerFluid = styled.div`
  width: 100%;
`;

export const FullWidthCard = styled(ContainerFluid)`
  margin: -2px 0 48px;
  min-height: 25vh;
  background: white;
  border-top: 1px solid #ededed;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.06);
`;

export const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 ${Grid._4};
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    padding: 0;
  }
`;

export const RaisedContainer = styled(Container)`
  background: ${Color.white};
  border: 1px solid ${Color.Gray._10};
  border-radius: ${Border.radius};
  box-shadow: ${Shadow.light};
`;

export const FixedHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: ${props => (props.fullViewport ? '100vh' : '100%')};
  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    display: ${props => (props.scrollSm ? 'block' : 'flex')};
    overflow-y: ${props => (props.scrollSm ? 'scroll' : 'hidden')};
  }

  > * {
    flex: 0 0 auto;
  }
`;

export const FixedHeaderContent = styled.div`
  flex: 1 1 100%;
  overflow-y: scroll;
`;

const AspectRatioContainerContent = styled.div.attrs(props => ({
  className: props.actionable ? 'actionable' : '',
}))`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props => (props.background ? props.background : Color.Gray._20)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : Border.radius)};
  border: ${props => (props.border ? props.border : 'none')};

  &.actionable {
    background: ${Color.Gray._10};
    border: 1px dashed ${Color.Gray._50};
  }
`;

const AspectRatioContainer = styled.div`
  width: 100%;
  position: relative;
  outline: none;
  max-height: 100%;

  &.six-one-aspect-ratio {
    padding-top: 16.66%; /* Percentage ratio for 6:1 */
  }
  &.three-two-aspect-ratio {
    padding-top: 66.66%; /* Percentage ratio for 3:2 */
  }
  &.sixteen-nine-aspect-ratio {
    aspect-ratio: 16/9;
  }
  &.sixteen-seven-aspect-ratio {
    padding-top: 43.75%; /* Percentage ratio for 16:7 */
  }
  &.curriculum-volume-image-placeholder {
    padding-top: 128%; /* Uncommon ratio - only for Curriculum Volume images */
  }
`;

const AspectRatioContainerFactory = ratioClass =>
  function AspectRatioContainerEntity(props) {
    return (
      <AspectRatioContainer className={ratioClass || ''}>
        <AspectRatioContainerContent {...props}>{props.children}</AspectRatioContainerContent>
      </AspectRatioContainer>
    );
  };
export const ThreeTwoAspectRatioPlaceholder = AspectRatioContainerFactory('three-two-aspect-ratio');
export const SixOneAspectRatioPlaceholder = AspectRatioContainerFactory('six-one-aspect-ratio');
export const SixteenNineAspectRatioPlaceholder = AspectRatioContainerFactory('sixteen-nine-aspect-ratio');
export const SixteenSevenAspectRatioPlaceholder = AspectRatioContainerFactory('sixteen-seven-aspect-ratio');

const DotContainer = styled.div`
  font-size: ${Type.Scale._5};
  color: ${Color.Gray._50};
  > span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 5px;
    background: ${Color.Navy._50};
    animation: fadeInOut 1s infinite;
    &.loading-0 {
      animation-delay: 0.1s;
    }
    &.loading-1 {
      animation-delay: 0.3s;
    }
    &.loading-2 {
      animation-delay: 0.5s;
    }
  }
`;

export const InlineDotLoadingAnimation = () => {
  return (
    <DotContainer>
      <span className="loading-0"></span>
      <span className="loading-1"></span>
      <span className="loading-2"></span>
    </DotContainer>
  );
};

export const CardPoster = styled.div`
  max-width: 100%;
  position: relative;
  padding-top: 57.5%; /* Percentage ratio for 16:9 */
  background-color: ${Color.Gray._20};
  background-size: cover;
  background-image: url(${props => props.src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: ${Border.radius} ${Border.radius} 0 0;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  background: white;
  font-weight: ${Type.Weight.bold};
  color: ${Type.Color.dark};
  border: 1px solid ${Color.Gray._10};
  border-radius: ${Border.radius};
  box-shadow: ${Shadow.small};
  margin: 0;
  height: 100%;
  width: 100%;
  transition: ${Transition.fast};
  &:hover:not(.disabled) {
    cursor: pointer;
    box-shadow: ${Shadow.medium};
    h4 {
      text-decoration: underline;
    }
  }
  & > div:first-child,
  & > div.sixteen-nine-aspect-ratio > div {
    border-radius: ${Border.radius} ${Border.radius} 0 0;
  }
  &.disabled {
    pointer-events: none;
  }
  button {
    position: absolute;
    top: ${Grid._3};
    right: ${Grid._3};
  }
`;

const VideoAspectRatioContainerContent = styled.div.attrs(props => ({
  className: props.thirdParty ? 'third-party-video-player' : '',
}))`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #222;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : Border.radius)};
`;

export const SixteenNinePlaceholderAnimation = props => (
  <SixteenNineAspectRatioPlaceholder {...props}>
    <InlineDotLoadingAnimation />
  </SixteenNineAspectRatioPlaceholder>
);

export const LoadingVideoPlaceholder = () => <SixteenNinePlaceholderAnimation background="#222" />;

export const VideoPlaceholder = props => (
  <AspectRatioContainer className="sixteen-nine-aspect-ratio">
    <VideoAspectRatioContainerContent {...props}>{props.children}</VideoAspectRatioContainerContent>
  </AspectRatioContainer>
);

export const CollectionImgPlaceholder = () => (
  <ThreeTwoAspectRatioPlaceholder>No Image Provided</ThreeTwoAspectRatioPlaceholder>
);

export const CollectionImgContainer = styled.div`
  flex-basis: 200px;
  img {
    box-shadow: ${Shadow.light};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: ${Border.radius};
  background: ${Color.Gray._20};
`;

export const ImageDimensionLabel = styled.p`
  margin: ${Grid._3} 0;
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._1};
  font-style: italic;
`;

export const InlineList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    font-size: ${props => (props.fontSize ? props.fontSize : Type.Scale._2)};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.medium};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '${props => props.content ?? '•'}';
      margin: 0 ${Grid._2};
    }
  }
`;

export const BulletedList = styled.ul`
  font-size: ${props => (props.fontSize ? props.fontSize : Type.Scale._3)};
  color: ${Type.Color.dark};
  li {
    margin-top: ${Grid._4};
  }
`;

export const SubHeaderWithButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    margin: 0 0 ${Grid._5} 0;
    flex: 1;
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin: 0;
    }
  }
`;

export const CurriculumVolumeImagePlaceholder = AspectRatioContainerFactory('curriculum-volume-image-placeholder');

export const PageTitle = styled.div`
  h1,
  h2 {
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    margin: 0;
  }
  p {
    font-size: ${Type.Scale._2};
    color: ${Type.Color.medium};
    margin: ${Grid._3} 0 0 0;
  }
`;
