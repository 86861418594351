import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMultiFilter, useTextFilter, useValueFilter } from '../../hooks/filters';
import { getInvite } from '../api/getInvites';
import { EmailInvite, Invite, Invitee, isEasyLinkInvite } from '../models/invite';
import { Status } from '../models/status';

type State = {
  isLoading: boolean;
  invite?: Invite;
  error?: AxiosError;
};

export const useViewInvite = (inviteId: string, orgId: string) => {
  const [data, setData] = useState<State>({
    isLoading: true,
  });

  useEffect(() => {
    getInvite(orgId, inviteId)
      .then(invite => setData({ invite, isLoading: false }))
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, error });
      });
  }, []);

  const statusMatcher = (invitee: Invitee, status: Status) => invitee.status === status;
  const queryMatcher = (invitee: Invitee, query: string) =>
    [invitee.email, invitee.fullName].some(name => name.toLowerCase().includes(query.toLowerCase()));

  const statusFilter = useValueFilter({ matcher: statusMatcher, emptyState: '' });
  const queryFilter = useTextFilter({ matcher: queryMatcher });

  const multiFilter = useMultiFilter({
    status: statusFilter,
    query: queryFilter,
  });

  const { filters } = multiFilter;

  const recipients = !data.invite
    ? []
    : isEasyLinkInvite(data.invite)
    ? data.invite.redemptions
    : (data.invite as EmailInvite).invitees;
  const filteredRecipients = recipients && multiFilter.filter([...recipients]);

  return {
    ...data,
    filters,
    recipients,
    filteredRecipients,
  };
};
