import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Grid, Border, Color, Shadow, Type, Transition, Breakpoints } from '../StyleGuide';
import ErrorMessage from '../components/ErrorMessage';
import { SmallRoundedIconButton } from '../components/Buttons';

const DISABLED_OPACITY = 0.6;

export const Header = styled.h1`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.semibold};
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 ${Grid._3};
  color: ${Type.Color.dark};
  font-weight: ${Type.Weight.semibold};

  &[disabled] {
    opacity: ${DISABLED_OPACITY};
  }
`;

export const Input = styled.input`
  background: white;
  border: 1px solid ${Color.Blue._20};
  border-color: ${props => (props.isInvalid ? Color.Red._50 : Color.Blue._20)};
  box-sizing: border-box;
  border-radius: ${Border.radius};
  padding: 12px;
  width: 100%;
  outline: none;
  box-shadow: inset ${Shadow.small};
  color: ${Type.Color.dark};
  transition: ${Transition.fast};
  ::placeholder {
    color: ${Type.Color.placeholder};
  }
  &:focus {
    /* box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Gray
      ._50}, 0px 0px 0px 5px rgba(179, 179, 179, 0.3); */
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._50},
      0px 0px 0px 5px rgba(172, 211, 239, 0.3);
  }
  &:focus.valid {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Primary._50},
      0px 0px 0px 5px rgba(76, 179, 124, 0.3);
  }
  &:focus.invalid {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Red._50},
      0px 0px 0px 5px rgba(179, 76, 76, 0.3);
  }
  &:disabled {
    opacity: ${DISABLED_OPACITY};
    cursor: not-allowed;
  }

  &[type='number']&[type='number'] {
    /* higher specificity to overwrite legacy styles */
    width: 100% !important;
    margin: 0;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  input {
    padding-right: ${Grid._8};
  }
  div {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: ${Grid._8};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .fas {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
  }
  .fa-search {
    position: absolute;
    top: 50%;
    transform: translate(-8px, -8px);
    left: ${Grid._5};
    opacity: 0.75;
  }
`;

export const AutoFocusInput = ({ onChangeHandler, clearInput, selectTextOnFocus, ...props }) => {
  const handleFocus = (event, selectTextOnFocus) => (selectTextOnFocus ? event.target.select() : false);

  return (
    <InputContainer>
      <Input onFocus={() => handleFocus(event, selectTextOnFocus)} type="text" onChange={onChangeHandler} {...props} />
      {props.value && (
        <div onClick={clearInput}>
          <span className="fas fa-times-circle"></span>
        </div>
      )}
    </InputContainer>
  );
};

AutoFocusInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
  clearInput: PropTypes.func,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  selectTextOnFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  readOnly: PropTypes.bool,
};

/**
 * @type React.FC<any>
 */
export const TextFilterInput = ({ onChangeHandler, onKeyDownHandler = () => {}, clearInput, ...props }) => (
  <InputContainer>
    <Input
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      style={{ paddingLeft: `${Grid._8}`, paddingRight: `${Grid._8}` }}
      {...props}
    />
    <span className="fas fa-search"></span>
    {props.value && (
      <div onClick={clearInput}>
        <span className="fas fa-times-circle"></span>
      </div>
    )}
  </InputContainer>
);

TextFilterInput.propTypes = {
  value: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  clearInput: PropTypes.func,
};

const Fieldset = styled.fieldset`
  legend {
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
    border-bottom: 2px solid ${Color.Blue._15};
    padding: ${Grid._3} 0;
    margin-bottom: ${Grid._6};
  }
`;

//The FormFieldSet component is used to group related data in a form
export const FormFieldSet = props => {
  return (
    <Fieldset className={props.className}>
      <legend>{props.name}</legend>
      {props.children}
    </Fieldset>
  );
};

export const FormFieldContainer = styled.div`
  margin-bottom: ${Grid._6};
`;

/**
 * @type React.FC<any>
 */
//The FormField component expects Formik and Yup properties for validation
/**
 * @type React.FC<any>
 */
export const FormField = React.forwardRef(
  ({ id, label, disabled = false, Wrapper = FormFieldContainer, ...props }, ref) => {
    const [field, meta] = useField(props);
    const inputId = id || props.name;
    const status = classNames({
      invalid: meta.touched && meta.error,
    });

    return (
      <Wrapper>
        <Label htmlFor={inputId} disabled={disabled}>
          {label}
        </Label>
        <Input {...field} {...props} id={inputId} disabled={disabled} className={status} ref={ref} />
        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
      </Wrapper>
    );
  }
);

FormField.displayName = 'FormField';

FormField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  Wrapper: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

//This FormField component uses a warning countdown status indicator as a maxlength warning and does not allow more characters than the maxlength to be entered
export const FormFieldMaxCharCountStatus = ({ label, Wrapper = FormFieldContainer, ...props }) => {
  const [field, meta] = useField(props);
  const status = classNames('form-field-max-char-count-status', {
    invalid: meta.error || field.value.length === props.maxLength,
  });
  const showCharCountWarningThreshold = props.maxLength / 2;
  return (
    <Wrapper>
      <Label htmlFor={props.id || props.name} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {label}
        {field.value && field.value.length > showCharCountWarningThreshold && (
          <span
            data-qa-hook="maxCharacterWarning"
            className={field.value.length > props.maxLength * 0.75 ? 'text-danger' : 'text-warning'}
          >
            {props.maxLength - field.value.length} characters left
          </span>
        )}
      </Label>
      <Input {...field} {...props} className={status} maxLength={props.maxLength} />
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Wrapper>
  );
};

FormFieldMaxCharCountStatus.propTypes = {
  label: PropTypes.any,
  Wrapper: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
};

export const FormFieldDate = styled(FormFieldContainer)`
  .react-datepicker-wrapper {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.5;

    input {
      opacity: 1;
    }
  }
`;

const StyledSelect = styled.select`
  padding: 12px;
  background: white;
  border-radius: ${Border.radius};
  border-color: ${Color.Blue._20};
  width: 100%;
  text-align-last: center;
  box-shadow: inset ${Shadow.small};
  color: ${Type.Color.dark};
  transition: ${Transition.fast};
  outline: none;
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._50},
      0px 0px 0px 5px rgba(172, 211, 239, 0.3);
  }
  :disabled {
    color: ${Type.Color.placeholder};
    border-color: ${Color.Gray._20};
    background: ${Color.Gray._10};
    box-shadow: none;
  }
  &[data-placeholder-active='true'] {
    color: ${Type.Color.placeholder};
  }
  option.select-placeholder {
    display: none;
  }
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    text-align-last: left;
  }
`;

/**
 * @type React.FC<any>
 */
export const Select = React.forwardRef(({ placeholder, children, ...props }, ref) => (
  <StyledSelect ref={ref} {...props} data-placeholder-active={placeholder && !props.value}>
    {placeholder && (
      <option className="select-placeholder" value="" disabled>
        {placeholder}
      </option>
    )}
    {children}
  </StyledSelect>
));

Select.displayName = 'Select';

Select.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
};

export const SmallRoundedIconSelect = styled.select`
  position: relative;
  background: ${Color.Gray._20};
  border-radius: 50%;
  width: ${Grid._6};
  height: ${Grid._6};
  border: 0;
  color: ${Type.Color.medium};
  transition: ${Transition.fast};
  outline: none;
  line-height: 0.7;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align-last: center;
  /* Browser support for centering Select text is not supported by Safari. This is a hack that seems to work for now.  */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      text-indent: 8px;
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    &:hover {
      background: ${Color.Gray._30};
      cursor: pointer;
    }
    &:focus {
      box-shadow: 0px 0px 0px 1px ${Color.Gray._50}, 0px 0px 0px 5px rgba(179, 179, 179, 0.3);
    }
    &:active {
      box-shadow: inset 0 0 2px 7px rgba(0, 0, 0, 0.03);
      outline: 0;
    }
  }
`;

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  appearance: none;
  cursor: pointer;
  top: 2px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  &:before,
  &:after {
    content: '';
    width: 50px;
    height: 50px;
    background: ${props => props.backgroundColor ?? Color.white};
    display: block;
    position: absolute;
    margin: -1px 0 0 -1px;
  }
  &:after {
    border: 2px solid ${Color.Gray._20};
    border-radius: 100%;
  }
  &:checked:after {
    background: ${props => props.checkedColor ?? Color.Primary._50};
    border-color: ${props => props.checkedColor ?? Color.Primary._50};
    box-shadow: inset 0 0 0 2px ${props => props.backgroundColor ?? Color.white};
  }
  &:focus:before {
    border-radius: 100%;
    box-shadow: 0px 0px 0px 3px rgba(172, 211, 239, 0.3);
  }
`;

export const Radio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  appearance: none;
  cursor: pointer;
  top: 2px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 18px;
  min-width: 18px;
  height: 18px;
  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 20px;
    background: ${props => props.backgroundColor ?? Color.white};
    display: block;
    position: absolute;
    margin: -1px 0 0 -1px;
    border-radius: 100%;
  }
  &:after {
    border: 2px solid ${Color.Gray._70};
    border-radius: 100%;
  }
  &:checked:after {
    background: ${props => props.checkedColor ?? Color.Primary._50};
    border-color: ${props => props.checkedColor ?? Color.Primary._50};
    box-shadow: inset 0 0 0 2px ${props => props.backgroundColor ?? Color.white};
  }
  &:focus:before {
    border-radius: 100%;
    box-shadow: 0px 0px 0px 3px rgba(172, 211, 239, 0.3);
  }
`;

export const RadioList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  li {
    position: relative;
    padding-left: 0;
    margin: ${Grid._5} auto;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${Type.Color.dark};
    text-transform: capitalize;
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.medium};
    margin: ${Grid._3} 0;
    i {
      color: ${Type.Color.medium};
      font-size: 20px;
      margin-right: ${Grid._3};
    }
  }
  input[type='radio'] {
    margin-right: ${Grid._4};
  }
`;

export const RadioListWithDescription = styled(RadioList)`
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    li {
      padding-left: ${Grid._6};
    }
  }
  p {
    color: ${Type.Color.medium};
  }
`;

export const InlineRadioList = styled(RadioList)`
  display: grid;
  grid-gap: ${Grid._4};
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;

  li {
    margin: 0;
    padding: ${Grid._3};

    ${props => props.backgroundColor && `background: ${props.backgroundColor}`}
  }

  label {
    display: flex;
    grid-template-columns: auto 1fr;

    > div {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 0;
      font-size: ${Type.Scale._2};

      p {
        padding: 0;
        margin: 0;
        color: ${Type.Color.medium};
        font-size: ${Type.Scale._1};
      }
    }
  }

  input[type='radio'] {
    margin-top: -3px;
    margin-right: ${Grid._4};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

export const InlineRadioListItem = ({ title, description, ...props }) => (
  <li style={{ backgroundColor: props.checked && Color.Secondary._10 }}>
    <label>
      <Radio backgroundColor={props.checked && Color.Secondary._10} {...props} />
      <div>
        {title}
        <p>{description}</p>
      </div>
    </label>
  </li>
);

export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p,
  label {
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._2};
    margin: 0;
  }
  p {
    flex: 1;
    margin: 0 0 ${Grid._4} 0;
  }
  .controls {
    display: flex;
    input[type='radio'] {
      margin-right: ${Grid._2};
    }
    label {
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: ${Grid._4};
      }
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    flex-direction: row;
    align-items: center;
    p {
      margin: 0 ${Grid._6} 0 0;
    }
  }
`;

const EditableText = styled.div`
  display: flex;
  align-items: center;
  padding: ${Grid._3};
  border-top: 1px solid ${Color.Blue._15};
  border-bottom: 1px solid ${Color.Blue._15};
  h4 {
    margin: 0;
    flex: 1;
    color: ${Type.Color.dark};
  }
`;

export const FormFieldInlineEdit = ({ label, value, editText }) => {
  return (
    <FormFieldContainer>
      <Label>{label}</Label>
      <EditableText>
        <h4>{value}</h4>
        <SmallRoundedIconButton onClick={() => editText()}>
          <i className="fas fa-pen" />
        </SmallRoundedIconButton>
      </EditableText>
    </FormFieldContainer>
  );
};

export const Steps = styled.ul`
  list-style: none;
  margin: ${Grid._5} 0;
  padding: 0;
  li.active-tab {
    border-bottom: 2px solid ${Color.Orange._50};
    > button {
      color: ${Type.Color.dark};
    }
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    border: 0;
    background: none;
    padding: ${Grid._3} 0;
    text-align: left;
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.medium};
    span {
      font-size: ${Type.Scale._2};
      font-weight: ${Type.Weight.light};
      color: ${Type.Color.medium};
    }
  }
`;

const CircleIcon = styled.i`
  font-size: ${Type.Scale._5};
  color: ${Type.Color.medium};
  height: fit-content;
  width: fit-content;
`;

export const CircleCheck = ({ checked }) => (
  <CircleIcon className={checked ? 'fas fa-check-circle fa-fw color-complete' : 'far fa-circle fa-fw'} />
);

CircleCheck.propTypes = {
  checked: PropTypes.bool,
};

export const SelectMultiple = ({ label, options, selections, onSelection, ...props }) => {
  const defaultValue = selections.length ? selections.length + ' Selected' : 'None Selected';

  const renderOptions = () =>
    options.map(option => {
      const { name, value } = typeof option === 'string' ? { name: option, value: option } : option;

      return (
        <option key={value} value={value}>
          {selections.includes(value) ? '✓ ' : ' '} {name}
        </option>
      );
    });

  return (
    <>
      <Label>{label}</Label>
      <Select value={defaultValue} onChange={event => onSelection(event.target.value)} {...props}>
        <option defaultValue>{defaultValue}</option>
        {renderOptions()}
      </Select>
    </>
  );
};

SelectMultiple.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selections: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const DatePickerContainer = styled.div`
  position: relative;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${Grid._4};
    font-size: ${Type.Scale._4};
  }

  ${Input} {
    padding-left: ${Grid._7};

    :disabled {
      color: ${Type.Color.placeholder};
      background: ${Color.Gray._10};
      border-color: transparent;
    }
  }
`;

export const DatePickerInput = React.forwardRef((props, ref) => (
  <DatePickerContainer>
    <i className="far fa-calendar-alt" />
    <Input ref={ref} {...props} />
  </DatePickerContainer>
));

DatePickerInput.displayName = 'DatePickerInput';

const withFormField =
  Component =>
  // eslint-disable-next-line react/display-name, react/prop-types
  ({ name, label = '', valueKey = name, className = '', ...props }) => {
    const { errors, touched } = useFormikContext();

    return (
      <FormFieldContainer className={className}>
        {label && <Label htmlFor={name}>{label}</Label>}
        <Component id={name} name={name} valueKey={valueKey} {...props} />
        {errors[valueKey] && touched[valueKey] && <ErrorMessage>{errors[valueKey]}</ErrorMessage>}
      </FormFieldContainer>
    );
  };

withFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  valueKey: PropTypes.string,
  className: PropTypes.string,
};

export const DatePickerField = withFormField(({ valueKey, ...props }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <DatePicker
      customInput={<DatePickerInput />}
      selected={values[valueKey]}
      onChange={date => setFieldValue(valueKey, date)}
      {...props}
    />
  );
});

DatePickerField.propTypes = {
  ...withFormField.propTypes,
};

export const SelectField = withFormField(({ valueKey, options = [], placeholder = '', children, ...props }) => {
  const { values, handleChange } = useFormikContext();

  return (
    <Select
      value={values[valueKey]}
      placeholder={options.length > 1 ? placeholder : null}
      onChange={handleChange}
      {...props}
    >
      {options.map(children)}
    </Select>
  );
});

SelectField.propTypes = {
  ...withFormField.propTypes,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  children: PropTypes.func,
};
