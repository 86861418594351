import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useCurriculum from '../hooks/useCurriculum';

const CurriculumContext = React.createContext();
CurriculumContext.displayName = 'CurriculumContext';

export const CurriculumProvider = ({ brandCode, ageCategory, children }) => {
  const { crmBrandCode, crmAgeCategory } = useParams();
  const curriculum = useCurriculum(brandCode ?? crmBrandCode, ageCategory ?? crmAgeCategory);

  return <CurriculumContext.Provider value={curriculum}>{children}</CurriculumContext.Provider>;
};

CurriculumProvider.propTypes = {
  brandCode: PropTypes.string,
  ageCategory: PropTypes.string,
  children: PropTypes.any,
};

export const CurriculumBrandWrapper = ({ brandCode, ageCategory, children }) => {
  const curriculum = useCurriculum(brandCode, ageCategory);
  return curriculum && !curriculum.brand.hidden ? children(curriculum) : null;
};

CurriculumBrandWrapper.propTypes = {
  brandCode: PropTypes.string,
  ageCategory: PropTypes.string,
  children: PropTypes.any,
};

export default CurriculumContext;
