import React from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../../components/Buttons';
import { FormField, Radio, RadioListWithDescription } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { useUser } from '../../../authentication';
import useManageTrainingRoute from '../../../hooks/useManageTrainingRoute';
import assessmentService from '../../../services/assessmentService';
import windowService from '../../../services/windowService';
import uuid from '../../../services/uuid';
import { handleError } from '../../../utils/apiUtils';
import StepIcons from '../../../StepIcons';
import { ContentTypes, ContentType } from '../../models/content';

const Icon = styled.i`
  font-size: 16px !important;
`;

type Params = {
  closeModal: () => void;
  ownerId: string;
};

type FormValues = {
  name: string;
  contentType?: ContentType;
};

export const AddContentModal = ({ ownerId, closeModal }: Params) => {
  const user = useUser();
  const { manageTrainingType, manageTrainingRoutePrefix } = useManageTrainingRoute();

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    if (!user || !values.contentType) return;

    if (values.contentType === 'Download' || values.contentType === 'Video') {
      return windowService.redirectTo(`${manageTrainingType}/content/creating/${values.contentType}`);
    }

    assessmentService
      .createAssessment(uuid.generate(), user.userId, ownerId, values.name)
      .then(response => windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${response.id}`))
      .catch(error => {
        if (error.eventType === 'AssessmentNameDuplicateError') {
          setSubmitting(false);
          setErrors({ name: 'Sorry, that assessment name is taken' });
        } else {
          handleError(error);
          closeModal();
        }
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader>
        <h3>Add Content</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(64, 'Name is too long')
            .when('contentType', {
              is: 'Assessment',
              then: Yup.string().required('A name is required'),
            }),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <h3>What type of content do you want to add?</h3>
              <RadioListWithDescription>
                {ContentTypes.map(type => (
                  <li key={type}>
                    <label>
                      <Radio
                        name="content-type"
                        onChange={() => setFieldValue('contentType', type)}
                        checked={values.contentType === type}
                      />
                      <Icon className={StepIcons[type]} /> {type}
                    </label>
                    {type === 'Assessment' && (
                      <FormField
                        label="Assessment Name"
                        name="name"
                        type="text"
                        placeholder="Enter Assessment Name"
                        disabled={values.contentType !== 'Assessment'}
                      />
                    )}
                  </li>
                ))}
              </RadioListWithDescription>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                type="submit"
                operating={isSubmitting}
                disabled={values.contentType === 'Assessment' ? !values.name : !values.contentType}
              >
                Add Content
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
