import React, { useEffect, useState } from 'react';
import { useSearch } from '../hooks/useSearch';
import { TextFilterInput } from '../../components/FormElements';
import { PrimaryButton } from '../../components/Buttons';

export const SearchInput = () => {
  const { searchText, updateSearchText, status } = useSearch();
  const [inputValue, setInputValue] = useState(searchText);
  const isPending = status.isLoading || status.isSearching;

  const submitSearchText = (searchText: string) => {
    updateSearchText(searchText);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') submitSearchText(inputValue);
  };

  const clearInput = () => {
    setInputValue('');
    submitSearchText('');
  };

  useEffect(() => {
    // listens for external changes to searchText, mainly reset
    if (searchText !== inputValue) setInputValue(searchText);
  }, [searchText]);

  return (
    <>
      <TextFilterInput
        data-qa-hook="searchFilter"
        id="searchTraining"
        className="searchTraining"
        placeholder="Search Training"
        value={inputValue}
        onChangeHandler={handleInputChange}
        onKeyDownHandler={handleKeyDown}
        clearInput={clearInput}
      />
      <PrimaryButton
        className="searchButton"
        data-qa-hook="searchSearch"
        disabled={!inputValue || isPending}
        operating={isPending}
        onClick={() => submitSearchText(inputValue)}
      >
        Search
      </PrimaryButton>
    </>
  );
};
