import apiClient from '../services/apiClient';

const events = Object.freeze({
  LICENSE_PAIRED: 'handleLicensePaired',
  CURRICULUM_TRIAL_GRANTED: 'handleCurriculumTrialGranted',
  ITEM_CARTED: 'handleItemCarted',
});

class TrackingAdapter {
  handleLicensePaired() {}
  handleCurriculumTrialGranted() {}
  handleItemCarted() {}
}

class IntercomAdapter extends TrackingAdapter {
  #callIntercom(...args) {
    try {
      window.Intercom(...args);
    } catch (e) {
      console.error('Intercom event failed', e);
    }
  }

  #sendIntercomEvent(eventName, data) {
    this.#callIntercom('trackEvent', eventName, data);
  }

  handleLicensePaired(data) {
    const itemNumber = data?.item?.number;
    const brandCode = data?.curriculum?.brand?.code;
    const ageCategory = data?.curriculum?.curriculum?.name;

    this.#sendIntercomEvent('license_paired', { itemNumber });

    if (brandCode && ageCategory) {
      this.#sendIntercomEvent(`${brandCode.toLowerCase()}_${ageCategory.toLowerCase()}_paired`, {
        brandCode,
        ageCategory,
      });
    }
  }

  handleCurriculumTrialGranted(data) {
    if (!data.brandCode && !data.ageCategory) return console.error('Invalid event structure');

    const { brandCode, ageCategory } = data;
    this.#sendIntercomEvent(`${brandCode.toLowerCase()}_${ageCategory.toLowerCase()}_trial_granted`, {
      brandCode,
      ageCategory,
    });
  }
}

class ActiveCampaignAdapter extends TrackingAdapter {
  #callTrackingAPI(eventName, email) {
    if (window.location.host !== 'ministrygrid.lifeway.com') return Promise.resolve();

    return apiClient.post('/trackingAC', {
      event: {},
      eventName,
      email,
    });
  }

  handleItemCarted(data) {
    const { itemNumber, userEmail } = data;
    return this.#callTrackingAPI(`Carted_${itemNumber}`, userEmail);
  }
}

const adapters = [new IntercomAdapter(), new ActiveCampaignAdapter()];

export default function useEventTracking() {
  const trackEvent = (event, data) => {
    if (!Object.values(events).includes(event)) {
      console.error('Invalid tracking event');
      return [];
    }

    return adapters.map(adapter => adapter[event](data)).filter(handler => handler instanceof Promise);
  };

  return { trackEvent, trackingEvents: events };
}
