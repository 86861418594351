import { get } from 'http';
import cacheableRequest from '../../services/cacheableRequest';
import { EasyLinkInvite, EmailInvite } from '../models/invite';

type InviteResponse = {
  _id: { id: string };
  _type: string;
  name: string;
  email: string;
  orgId: { id: string };
  active: boolean;
};

type MembershipCode = {
  prefix: string;
  suffix: string;
};
type EasyLinkInviteResponse = InviteResponse & {
  membershipCode: MembershipCode;
  redemptions: {
    firstName: string;
    lastName: string;
    email: string;
  }[];
  redemptionLimit?: number;
  workflowId: string;
};

type EmailInviteResponse = InviteResponse & {
  invitees: {
    firstName: string;
    lastName: string;
    email: string;
    status: 'Accepted' | 'Pending' | 'Removed';
  }[];
  wfId: string;
};

type GetInvitesRepsonse = {
  data: {
    invites: InviteResponse[];
  };
};

const isEasyLinkInviteResponse = (invite: InviteResponse): invite is EasyLinkInviteResponse =>
  'membershipCode' in invite;

const formatFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;

const formatRedemptionLink = (membershipCode: MembershipCode) =>
  `${window.location.origin}/#/redeem-invite/${membershipCode.prefix}/${membershipCode.suffix}`;

const formatMembershipCode = (membershipCode: MembershipCode) => `${membershipCode.prefix}-${membershipCode.suffix}`;

const generateEmailSearchData = (invite: EmailInviteResponse) =>
  [
    invite.name,
    invite.email,
    ...(invite.invitees || []).map(invitee => [invitee.firstName, invitee.lastName, invitee.email].join(' ')),
  ].join(' ');

const generateEasyLinkSearchData = (invite: EasyLinkInviteResponse) =>
  [
    invite.name,
    invite.email,
    formatMembershipCode(invite.membershipCode),
    ...invite.redemptions.map(redemption => [redemption.firstName, redemption.lastName, redemption.email].join(' ')),
  ].join(' ');

const transformResponseToInvites = (response: GetInvitesRepsonse) => {
  if (!Array.isArray(response?.data?.invites)) return [];

  return response.data.invites.map((_invite: InviteResponse) => {
    if (isEasyLinkInviteResponse(_invite)) {
      const invite = _invite as EasyLinkInviteResponse;
      const redemptions = (invite.redemptions || []).map(redemption => ({
        ...redemption,
        fullName: formatFullName(redemption.firstName, redemption.lastName),
      }));

      return {
        ...invite,
        type: 'code',
        id: invite._id.id,
        orgId: invite.orgId.id,
        workflowId: invite.workflowId,
        membershipCode: formatMembershipCode(invite.membershipCode),
        redemptions,
        redemptionLink: formatRedemptionLink(invite.membershipCode),
        redemptionCount: redemptions.length,
        redemptionLimit: invite.redemptionLimit ?? null,
        searchData: generateEasyLinkSearchData(invite),
      } as EasyLinkInvite;
    }

    const invite = _invite as EmailInviteResponse;
    const invitees = (invite.invitees || []).map(invitee => ({
      ...invitee,
      fullName: formatFullName(invitee.firstName, invitee.lastName),
      status: invitee.status === 'Removed' ? 'Disabled' : invitee.status,
    }));

    return {
      ...invite,
      type: 'email',
      id: invite._id.id,
      orgId: invite.orgId.id,
      workflowId: invite.wfId,
      invitees,
      redemptionCount: invitees.filter(invitee => invitee.status === 'Accepted').length,
      redemptionLimit: invitees.length,
      searchData: generateEmailSearchData(invite),
    } as EmailInvite;
  });
};

type Invite = EmailInvite | EasyLinkInvite;

export const getInvites: (orgId: string) => Promise<Invite[]> = orgId =>
  cacheableRequest(`/v1/invites/${orgId}`).then(transformResponseToInvites);

export const getInvite: (orgId: string, inviteId: string) => Promise<Invite> = (orgId, inviteId) =>
  getInvites(orgId).then(
    (invites: Invite[]) => invites.find(invite => invite.id === inviteId) as EmailInvite | EasyLinkInvite
  );
