import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useUser } from '../authentication';
import { useBuildFilter } from './hooks/useBuildFilter';
import { Breakpoints, Grid } from '../StyleGuide';
import LoadingState from '../components/LoadingState';
import { Container } from '../components/Layout';
import ErrorMessage from '../components/ErrorMessage';
import { SelectMultiple, TextFilterInput } from '../components/FormElements';
import { ButtonGroup, PrimaryButton, SortButton } from '../components/Buttons';
import { ListItem } from './components/ListItem';
import alertService from '../services/AlertService';
import trainingService from '../services/trainingService';
import ActiveFilters from '../components/ActiveFilters';
import { AddCourseModal } from './components/modals/AddCourseModal';
import useModal from '../hooks/useModal';
import EmptyState from '../components/EmptyState';
import { RemoveTrainingModal } from './components/modals/RemoveTrainingModal';
import { Course } from './models/course';

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  grid-gap: ${Grid._4};
  align-items: end;
  margin-bottom: ${Grid._6};

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SortButtonGroup = styled(ButtonGroup)`
  margin-bottom: ${Grid._3};
`;

type Props = {
  ownerId: string;
};

type Data = {
  tasklists: Course[];
  isError: boolean;
  isLoading: boolean;
  categories: string[];
};

type RemoveCourseErrorResponse = {
  workflows: string[];
  assignedCount: number;
  groups: string[];
};

export const CourseList = ({ ownerId }: Props) => {
  const user = useUser();
  const [data, setData] = useState<Data>({
    tasklists: [],
    categories: [],
    isLoading: true,
    isError: false,
  });

  const { filteredItems, multiFilter, sortFilter } = useBuildFilter<Course>(data.tasklists);
  const { filters } = multiFilter;

  useEffect(() => {
    if (ownerId) {
      trainingService
        .getCourses(ownerId, true)
        .then((response: Course[]) => {
          const tasklists = response;
          const uniqueCategories = new Set<string>();

          for (const tasklist of tasklists) {
            const category = tasklist.category;
            if (category !== undefined && !uniqueCategories.has(category)) {
              uniqueCategories.add(category);
            }
          }

          const categoriesArray = Array.from(uniqueCategories);

          categoriesArray.sort();
          categoriesArray.push('No Category');

          setData({
            tasklists: tasklists,
            categories: categoriesArray,
            isLoading: false,
            isError: false,
          });
        })
        .catch(() => setData({ tasklists: [], categories: [], isError: true, isLoading: false }));
    }
  }, [ownerId]);

  const displayErrorMessage = (error: RemoveCourseErrorResponse) => {
    if (error.workflows.length > 0 && error.assignedCount > 0)
      return alertService.showError(
        `This course cannot be removed because it is part of ${
          error.assignedCount
        } assignments and the following pathways: ${error.workflows.join(', ')}`
      );

    if (error.workflows.length > 0)
      return alertService.showError(
        `This course cannot be removed until it is removed from the following pathways: ${error.workflows.join(', ')}`
      );

    if (error.assignedCount > 0)
      return alertService.showError(
        `This course cannot be removed because it is assigned to ${error.assignedCount} pathway instances.`
      );

    if (error.groups.length > 0)
      return alertService.showError(
        `This course cannot be removed until it is removed from the following groups: ${error.groups.join(', ')}`
      );

    return alertService.showError(`There was an error deleting this course.`);
  };

  const removeCourse = (course: Course) =>
    trainingService
      .removeCourse(course.id, ownerId, user?.userId)
      .then(() => {
        alertService.show('Course Removed');
        setData(prev => ({ ...prev, tasklists: data.tasklists.filter(t => t.id !== course.id) }));
      })
      .catch((error: RemoveCourseErrorResponse) => {
        console.error(error);
        displayErrorMessage(error);
      })
      .finally(dismissHandler);

  const [modal, openModal, dismissHandler] = useModal(
    (type: string, payload: Course | undefined, dismissModal: () => void) => {
      // TODO: refactor `closeModal` to `handleSubmit`
      if (type === 'addCourse') return <AddCourseModal ownerId={ownerId} closeModal={dismissModal} />;
      if (type === 'removeCourse' && payload)
        return (
          <RemoveTrainingModal
            name={payload?.name || ''}
            type="course"
            handleSubmit={() => removeCourse(payload)}
            handleDismiss={dismissModal}
          />
        );
      return null;
    }
  );

  const activeFilters = {};
  // TODO: Fix these
  // @ts-expect-error
  if (!filters.status.isEmpty) activeFilters['Status'] = filters.status.reset;
  // @ts-expect-error
  if (!filters.category.isEmpty) activeFilters['Category'] = filters.category.reset;

  const showActiveFilters = !!Object.keys(activeFilters).length || !!filters.query?.value;

  return (
    <>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage />
      ) : (
        <Container>
          <OptionsContainer>
            <SelectContainer data-qa-hook="coursesStatusFilter">
              <SelectMultiple
                label="Status"
                options={['Show Published', 'Show Not Published']}
                selections={filters.status.values}
                onSelection={filters.status.update}
                data-qa-hook="taskListsStatusFilter"
              />
            </SelectContainer>
            <SelectContainer>
              <SelectMultiple
                label="Category"
                options={data.categories}
                selections={filters.category.values}
                onSelection={filters.category.update}
                data-qa-hook="taskListsCategoryFilter"
              />
            </SelectContainer>
            <TextFilterInput
              label="Search"
              data-qa-hook="coursesSearchFilter"
              value={filters.query.value}
              onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => filters.query.update(e.target.value)}
              placeholder="Search Courses"
              clearInput={() => filters.query.update('')}
            />
            <div className="primary-action">
              <PrimaryButton data-qa-hook="addCoursesButton" onClick={() => openModal('addCourse')}>
                Add Course
              </PrimaryButton>
            </div>
          </OptionsContainer>
          {showActiveFilters && (
            <ActiveFilters count={filteredItems?.length} filters={activeFilters} onClearAll={multiFilter.reset} />
          )}

          <SortButtonGroup>
            <SortButton
              sortActive={sortFilter.method === 'name'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('name')}
            >
              Name
            </SortButton>
            <SortButton
              sortActive={sortFilter.method === 'lastEdited'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('lastEdited')}
            >
              Last Edited
            </SortButton>
          </SortButtonGroup>

          {filteredItems.length === 0 ? (
            filters.query?.value ? (
              <EmptyState
                icon="fa-search"
                title="Your search does not have any matches"
                description="Please try another search."
              />
            ) : (
              <EmptyState title="No Courses Added" description="To add a course use the Add button." />
            )
          ) : (
            filteredItems.map((course: Course) => (
              <ListItem
                key={course.id}
                item={course}
                type="course"
                onRemoveClick={() => openModal('removeCourse', course)}
              />
            ))
          )}
          {modal}
        </Container>
      )}
    </>
  );
};
