import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import curriculumService from '../services/curriculumService';
import { sessionDateFilter } from '../filters/date-filters';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Container, FixedHeaderContainer, FixedHeaderContent } from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import BibleStudyDocument from './BibleStudyDocument';
import BibleStudyVideoCard from './BibleStudyVideoCard';
import BibleStudyAudioCard from './BibleStudyAudioCard';
import windowService from '../services/windowService';
import useCurriculum from '../hooks/useCurriculum';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import SessionImageAndDetails from './SessionImageAndDetails';
import SessionHeader from './SessionHeader';
import BibleStudyBanner from './BibleStudyBanner';

export default function BibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { sessionId, issueId } = useParams();
  const [data, setData] = useState({ isLoading: true });

  useEffect(() => {
    curriculumService
      .getSessionAndMaterials(issueId, sessionId)
      .then(session => {
        if (session.timelines?.length) redirectToTimeline(session.timelines[0].id);

        const { scheduledDate, availableThroughDate, isThisWeek } = sessionDateFilter(session);
        setData({
          isLoading: false,
          session: {
            ...session,
            scheduledDate,
            availableThroughDate,
            isThisWeek,
            documents: session.materials.filter(m => m.materialType === 'download' || m.materialType === 'text'),
            videos: session.materials.filter(m => m.materialType === 'video'),
            audio: session.materials.filter(m => m.materialType === 'audio'),
          },
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  const redirectToTimeline = timelineId =>
    windowService.redirectTo(
      `/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${issueId}/timeline/${timelineId}`,
      true
    );

  const redirectToMaterial = materialId =>
    windowService.redirectTo(`#/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/material/${materialId}`);

  return (
    <FixedHeaderContainer fullViewport>
      <div>
        <SessionHeader
          brandName={`${brand.name}: ${ageCategory}`}
          sessionName={data.session ? data.session.name : 'Loading...'}
          isLoading={!data.isLoading}
        />
      </div>
      <FixedHeaderContent id="session-timeline-container">
        <BibleStudyBanner />
        <Container style={{ paddingBottom: '132px' }}>
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError || !data.session ? (
            <ErrorMessage />
          ) : (
            <>
              <SessionImageAndDetails session={data.session} />

              {!!data.session?.documents.length && (
                <>
                  <SectionHeader title="Documents" />
                  {data.session.documents.map((document, i) => (
                    <BibleStudyDocument document={document} key={i} redirectToMaterial={redirectToMaterial} />
                  ))}
                </>
              )}
              {!!data.session?.videos.length && (
                <>
                  <SectionHeader title="Videos" margin="40px 0 32px" />
                  <div className="grid-container grid-col-1 grid-xs-col-2 grid-md-col-3 grid-gap-32">
                    {data.session.videos
                      .filter(m => m.materialType === 'video')
                      .map((video, i) => (
                        <BibleStudyVideoCard video={video} key={i} redirectToMaterial={redirectToMaterial} />
                      ))}
                  </div>
                </>
              )}
              {!!data.session?.audio.length && (
                <>
                  <SectionHeader title="Audio" margin="40px 0 32px" />
                  <div className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-32">
                    {data.session.audio.map((audio, i) => (
                      <BibleStudyAudioCard audio={audio} key={i} redirectToMaterial={redirectToMaterial} />
                    ))}
                  </div>
                </>
              )}
              {data.session && !data.session.materials.length && (
                <HelpAndInfoBox
                  title="Materials are on the way"
                  description="Authors are curating content for this session and it will be visible here soon."
                />
              )}
            </>
          )}
        </Container>
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}
