import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from './Breadcrumbs';
import SectionHeader from './SectionHeader';
import { handleError } from '../utils/apiUtils';
import { ImageCropUtils } from './ImageCrop';
import ProfileImageCrop from './ProfileImageCrop';
import LicenseListItem from '../manageSubscriptions/LicenseListItem';
import { Button, OutlineButton, ToggleButton } from './Buttons';
import { Container, ContainerFluid } from './Layout';
import { Grid, Breakpoints } from '../StyleGuide';
import { FormGroupContainer, FormFieldContainer, Label, Radio } from './FormElements';
import { ProfileCardContainer, ProfileCardDivider, ProfileCardSection } from './ProfileCard';
import { useUser } from '../authentication';
import useAngularScope from '../hooks/useAngularScope';
import userService from '../services/userService';
import alertService from '../services/AlertService';
import uploadService from '../services/uploadService';
import licenseService from '../services/licenseService';

const AccountProfileContainer = styled(Container)`
  display: flex;
  margin: ${Grid._5} auto;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    flex-direction: row;
  }
`;

const ProfileDataContainer = styled(ContainerFluid)`
  padding: ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    padding: ${Grid._4} ${Grid._8};
  }
`;

const IndividualLicensesContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0 !important;
  padding-left: 0 !important;
  display: grid;
  grid-row-gap: ${Grid._5};
`;

const ProfileImageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FullStoryFormFieldContainer = styled(FormFieldContainer)`
  & p {
    margin-bottom: ${Grid._4};
  }
`;

const PreferencesContainer = styled.div`
  margin-bottom: ${Grid._8};
`;

const disableContentsquareTracking = () =>
  new Promise((resolve, reject) => {
    const checkCSCookie = (attemptsRemaining, retryTimeout) => {
      if (attemptsRemaining <= 0) return reject('Unable to set Contentsquare opt out cookie');

      setTimeout(() => {
        if (document.cookie.indexOf('_cs_optout') >= 0) return resolve();
        checkCSCookie(attemptsRemaining - 1);
      }, retryTimeout);
    };

    window._uxa.push(['optout']);
    checkCSCookie(5, 500);
  });

const AccountProfile = () => {
  const user = useUser();
  const [, updateAngularScope] = useAngularScope();
  const [showTips, setShowTips] = useState(true);
  const [individualLicenses, setIndividualLicenses] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [awaitingProfileImage, setAwaitingProfileImage] = useState(false);
  const [isContentsquareTrackingEnabled, setIsContentsquareTrackingEnabled] = useState(
    document.cookie.indexOf('_cs_optout') < 0
  );
  const crumbs = [{ name: 'Dashboard', route: '#/dashboard' }, { name: 'Profile' }];

  useEffect(() => {
    if (user?.profileImageUrl) {
      setProfileImage(user?.profileImageUrl);
    }
  }, [user?.profileImageUrl]);

  useEffect(() => {
    userService.getPreferences().then(res => setShowTips(res.showAllInformationBoxes));
    licenseService
      .getIndividualLicenses()
      .then(res => setIndividualLicenses(res))
      .catch(handleError);
  }, []);

  const handlePreferencesChange = preference => {
    setShowTips(preference);
    userService
      .savePreferences(user.userId, preference)
      .then(() => alertService.show('Preferences saved', 'success'))
      .catch(handleError);
  };

  const handleContentsquareTrackingClick = () => {
    disableContentsquareTracking()
      .then(() => {
        alertService.show('Preferences saved');
        setIsContentsquareTrackingEnabled(false);
      })
      .catch(handleError);
  };

  const onSaveProfileImage = image => {
    if (!image) return;

    ImageCropUtils.convertCroppedImageToBlob(image)
      .then(blob => uploadService.uploadProfileImage(user.userId, blob))
      .then(() => userService.setProfileImage(user.userId))
      .then(() => userService.getProfile(user.userId))
      .then(res => {
        updateAngularScope(scope => {
          scope.userProfile.profile.imageUrl = res.profile.imageUrl;
        });
        setAwaitingProfileImage(true);
        setProfileImage(res.profile.imageUrl);
      })
      .catch(() => {
        alertService.showError();
        setAwaitingProfileImage(false);
      });
  };

  const onProfileImageLoaded = closeModal => {
    if (!awaitingProfileImage) return;

    closeModal();
    alertService.show('Profile Image Saved');
    setAwaitingProfileImage(false);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <AccountProfileContainer data-qa-hook="profileView">
        <ProfileCardContainer>
          <ProfileCardSection>
            <ProfileImageWrapper>
              <ProfileImageCrop
                onUpdate={onSaveProfileImage}
                profileImage={profileImage}
                onProfileImageLoaded={onProfileImageLoaded}
              />
            </ProfileImageWrapper>
          </ProfileCardSection>
          <ProfileCardSection>
            <h6 data-qa-hook="profileFullName">{user?.displayName}</h6>
            <p data-qa-hook="profileLoginEmail">{user?.logInEmail}</p>
          </ProfileCardSection>
          <ProfileCardDivider />
          <ProfileCardSection>
            <Button
              data-qa-hook="profileMyLifeWayLink"
              onClick={() => window.open('https://my.lifeway.com/', '_blank')}
            >
              Edit Lifeway Profile
            </Button>
          </ProfileCardSection>
        </ProfileCardContainer>
        <ProfileDataContainer>
          <SectionHeader title="Preferences" />
          <PreferencesContainer>
            <FormFieldContainer>
              <Label>Help and Information Tips</Label>
              <FormGroupContainer>
                <p>Show the Help and Information tips sections throughout the app</p>
                <div className="controls">
                  <Radio
                    data-qa-hook="showTips"
                    id="tips-yes"
                    name="showTips"
                    onChange={() => handlePreferencesChange(true)}
                    checked={showTips}
                  />
                  <label htmlFor="tips-yes">Yes</label>
                  <Radio
                    data-qa-hook="dontShowTips"
                    id="tips-no"
                    name="showTips"
                    onChange={() => handlePreferencesChange(false)}
                    checked={!showTips}
                  />
                  <label htmlFor="tips-no">No</label>
                </div>
              </FormGroupContainer>
            </FormFieldContainer>
            <FormFieldContainer>
              <Label>ContentSquare Analytics</Label>
              <FormGroupContainer>
                <p>
                  Allow the use of ContentSquare, a solution which aggregates usage and frequency data to improve user
                  experience. Statistics created are anonymized.
                </p>
                <div className="controls">
                  <ToggleButton
                    data-qa-hook="optOutContentsquare"
                    id="contentSquare-yes"
                    name="showContentSquare"
                    isOn={isContentsquareTrackingEnabled}
                    disabled={!isContentsquareTrackingEnabled}
                    onClick={handleContentsquareTrackingClick}
                  >
                    Tracking {!isContentsquareTrackingEnabled ? 'Off' : 'On'}
                  </ToggleButton>
                </div>
              </FormGroupContainer>
            </FormFieldContainer>
            <FullStoryFormFieldContainer>
              <Label>FullStory Analytics</Label>
              <p>
                Ministry Grid uses fullstory.com session replay and analytics capabilities to troubleshoot bugs, support
                our customers, and build better experiences. For more information, or to toggle you participation on and
                off, click the button below.
              </p>
              <OutlineButton
                data-qa-hook="optOutFS"
                onClick={() => window.open('https://www.fullstory.com/optout/', '_blank')}
              >
                Manage Fullstory Preferences
              </OutlineButton>
            </FullStoryFormFieldContainer>
          </PreferencesContainer>

          <SectionHeader title="Individual Subscriptions" />
          <IndividualLicensesContainer>
            {individualLicenses.map(license => (
              <LicenseListItem license={license} key={license.id} />
            ))}
          </IndividualLicensesContainer>
        </ProfileDataContainer>
      </AccountProfileContainer>
    </>
  );
};

export default AccountProfile;
