import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import { CircleCheck } from '../../components/FormElements';
import LoadingState from '../../components/LoadingState';
import EmptyState from '../../components/EmptyState';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import ErrorMessage from '../../components/ErrorMessage';
import { ContentTitle, ListItem, ContinueButton, ViewContainer, ViewPropTypes } from './OnboardingElements';
import useCurriculum from '../../hooks/useCurriculum';
import { PrimaryButton } from '../../components/Buttons';

const EmptyStateContainer = styled.div`
  text-align: center;

  .empty-state-ui {
    margin: 0;
  }

  button {
    margin-top: ${Grid._4};
  }
`;

const AgeGroupsContainer = styled(ViewContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: ${Grid._4};
`;

const hasItemNumber = (arr, itemNumber) => arr.some(x => x.itemNumber === itemNumber);

const AgeGroupsView = ({ data, handler }) => {
  const { brand } = useCurriculum();
  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [selected, setSelected] = useState(data.items ?? []);
  const [curriculum, setCurriculum] = useState([]);

  const onItemClick = item =>
    setSelected(prev => {
      const items = hasItemNumber(selected, item.itemNumber)
        ? prev.filter(i => i.itemNumber !== item.itemNumber)
        : [...prev, item];
      return items.filter(i => !!i);
    });

  const onContinueClick = () => handler(selected);

  useEffect(() => {
    curriculumService
      .getPricingByBrand(brand.code)
      .then(crm => {
        if (brand.isPreview) {
          setCurriculum(
            crm
              .filter(c => !!c.license[0]?.itemNumber)
              .map(c => ({ name: c.name, itemNumber: c.license[0].itemNumber }))
          );
        } else {
          setCurriculum(
            crm.filter(c => !!c.trialItemNumber).map(c => ({ name: c.name, itemNumber: c.trialItemNumber }))
          );
        }
        setStatus({ isLoading: false, isError: false });
      })
      .catch(error => {
        console.error(error);
        setStatus({ isLoading: false, isError: true });
      });
  }, []);

  return status.isLoading ? (
    <LoadingState />
  ) : status.isError ? (
    <ErrorMessage />
  ) : curriculum.length === 0 ? (
    <EmptyStateContainer>
      <EmptyState title="No Trials Available" description="View our full line of curriculum to get started today" />
      <PrimaryButton onClick={() => windowService.redirectTo('/bible-studies')}>View Curriculum</PrimaryButton>
    </EmptyStateContainer>
  ) : (
    <>
      <ContentTitle>
        <h2>Select Age Group</h2>
        <p>Select one or more curriculum age groups to try out</p>
      </ContentTitle>
      <AgeGroupsContainer data-qa-hook="ageGroupContainer">
        {curriculum.map(({ name, itemNumber }) => (
          <ListItem data-qa-hook="ageGroupItem" key={itemNumber} onClick={() => onItemClick({ itemNumber, name })}>
            <h2 data-qa-hook="ageGroupName">{name}</h2>
            <CircleCheck checked={hasItemNumber(selected, itemNumber)} />
          </ListItem>
        ))}
      </AgeGroupsContainer>
      <ContinueButton data-qa-hook="continueButton" disabled={selected.length === 0} onClick={onContinueClick}>
        Continue
      </ContinueButton>
    </>
  );
};

AgeGroupsView.propTypes = ViewPropTypes;

export default AgeGroupsView;
