import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '../StyleGuide';
import { Container } from '../components/Layout';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import MobileCrumb from '../components/MobileCrumb';
import { Buttons, PrimaryButton, Button } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import alertService from '../services/AlertService';
import catalogService from '../services/catalogService';
import windowService from '../services/windowService';
import { Header } from '../components/FormElements';
import ManageTrainingButtonSearchForm from './ManageTrainingButtonSearchForm';
import ManageTrainingButtonTrainingForm from './ManageTrainingButtonTrainingForm';
import { useUser } from '../authentication';

export default function ManageTrainingButton() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true, isError: false, isSubmitting: false });
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Catalog', route: '#/manage-catalog' },
    { name: '' },
  ]);

  const type = windowService.getRoutePart(3);
  const buttonId = windowService.getRoutePart(4);

  const ManageTrainingButtonForm =
    type === 'search' ? ManageTrainingButtonSearchForm : ManageTrainingButtonTrainingForm;

  const redirectBack = () => windowService.redirectTo(crumbs[crumbs.length - 2].route);

  useEffect(() => {
    const updateLastCrumbName = (crumbList, newName) => {
      crumbList[crumbList.length - 1] = { name: newName };
      return crumbList;
    };

    catalogService
      .getCatalogHomePage()
      .then(response => {
        if (buttonId) {
          const button = response.buttonsSection.buttons.find(button => button.id === buttonId);
          setCrumbs(crumbs => updateLastCrumbName(crumbs, button.text));
          setData(prevData => ({ ...prevData, catalog: response, button: button, isLoading: false }));
        } else {
          setCrumbs(crumbs => updateLastCrumbName(crumbs, 'New Training Button'));
          setData(prevData => ({ ...prevData, catalog: response, isLoading: false }));
        }
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  const submit = (text, buttonData) => {
    setData({ ...data, isSubmitting: true });
    const createOrUpdateFn = () =>
      buttonId
        ? catalogService.updateTrainingButton(
            data.catalog.id,
            data.catalog.buttonsSection.sectionId,
            buttonId,
            buttonData,
            text,
            user.userId
          )
        : catalogService.createTrainingButton(
            data.catalog.id,
            data.catalog.buttonsSection.sectionId,
            buttonData,
            text,
            user.userId
          );

    createOrUpdateFn()
      .then(() => {
        //Give the api a second to update
        setTimeout(() => {
          alertService.showOnNextPage('Training Catalog Updated');
          redirectBack();
        }, 1000);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isSubmitting: false });
      });
  };

  return (
    <>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      <Container className="visible-xs-block visible-sm-block" style={{ margin: `${Grid._4} 0 -${Grid._4} 0` }}>
        <MobileCrumb crumbs={crumbs} />
      </Container>
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="addEditTrainingButtonView" style={{ margin: `${Grid._6} auto` }}>
          <Header>{buttonId ? 'Edit' : 'New'} Training Button</Header>
          <ManageTrainingButtonForm button={data.button} onSubmit={submit}>
            <Buttons style={{ justifyContent: 'flex-end' }}>
              <Button data-qa-hook="cancel" onClick={redirectBack}>
                Cancel
              </Button>
              <PrimaryButton
                data-qa-hook="addSaveButton"
                type="submit"
                disabled={data.isSubmitting}
                operating={data.isSubmitting}
              >
                {buttonId ? 'Save Button' : 'Add Button'}
              </PrimaryButton>
            </Buttons>
          </ManageTrainingButtonForm>
        </Container>
      )}
    </>
  );
}
