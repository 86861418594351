import { PartialSearchResultItem } from '../../search';

export type InviteRole = {
  id: { id: string };
  name: string;
};

export type InviteGroup = {
  id: { id: string };
  name: string;
};

export type InviteTraining = {
  id: string;
  name: string;
  _type: string;
  active: boolean;
  trainingId: string;
  taskListId?: { id: string };
  workflowId?: { id: string };
};

export type InviteSettings = {
  roles: InviteRole[];
  licenses: never[];
  groups: InviteGroup[];
  trainings: InviteTraining[];
};

export const transformInviteTrainingToSearchResultItem = (training: InviteTraining): PartialSearchResultItem => {
  const isCourse = training._type.toLowerCase() === 'tasklist';
  return {
    id: training.id,
    name: training.name,
    trainingType: isCourse ? 'Tasklist' : 'Workflow',
  };
};

export const transformSearchResultItemToInviteTraining = (training: PartialSearchResultItem): InviteTraining => {
  const isCourse = training.trainingType.toLowerCase() === 'tasklist';
  return {
    id: training.id,
    name: training.name,
    _type: isCourse ? 'TaskList' : 'Workflow',
    trainingId: training.id,
    active: true,
    [isCourse ? 'taskListId' : 'workflowId']: { id: training.id },
  };
};
