import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Grid } from '../StyleGuide';
import { ContainerFluid } from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import Carousel from 'react-elastic-carousel';
import { carouselControls } from '../components/CarouselElements';
import CurriculumCarouselItem from './CurriculumCarouselItem';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { sessionDateFilter } from '../filters/date-filters';
import TeacherTimelinesOverlay from './timeline/TeacherTimelinesOverylay';
import sessionTimelineService from '../services/sessionTimelineService';
import windowService from '../services/windowService';
import useModal from '../hooks/useModal';
import useCurriculum from '../hooks/useCurriculum';

const HelpAndInfoContainer = styled.div`
  > div {
    height: 160px;
    margin: 0;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin: ${Grid._4} 0;
    }
  }
`;

const getCarouselStartingIndex = sessions => {
  const filteredSessions = sessions.map(s => sessionDateFilter(s));

  const thisWeekIndex = filteredSessions.findIndex(fs => fs.isThisWeek);
  if (thisWeekIndex > -1) return thisWeekIndex;

  const nextUpcomingIndex = filteredSessions.findIndex(fs => fs.scheduledDate > new Date());
  if (nextUpcomingIndex > -1) return nextUpcomingIndex;

  return sessions.length;
};

export default function CurriculumCarousel({ curriculum, sessions }) {
  const { brand } = useCurriculum(curriculum.brandCode);
  const [displaySessions] = useState(sessions.filter(s => !brand.timelineEnabled || s.timelines?.length > 0));

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (type === 'timelines')
      return <TeacherTimelinesOverlay curriculum={curriculum} session={payload.session} handleDismiss={dismissModal} />;

    return null;
  });

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 767, itemsToShow: 2, itemsToScroll: 2 },
  ];

  const carouselIndex = getCarouselStartingIndex(displaySessions);

  const handleSessionClick = session => {
    const { sessionId, issueId } = session;

    if (!session.timelines?.length) {
      return windowService.redirectTo(
        `#/curriculum/${curriculum.brandCode}/${curriculum.name}/session/${sessionId}/issue/${issueId}`
      );
    }

    if (session.timelines.length === 1) {
      const [timeline] = session.timelines;
      return windowService.redirectTo(
        sessionTimelineService.getTeacherSessionTimelineUrl(curriculum, session, timeline)
      );
    }

    openModal('timelines', { session });
  };

  const carouselItems = [
    ...displaySessions.map(session => (
      <CurriculumCarouselItem
        key={session.sessionId}
        session={session}
        timelineEnabled={brand.timelineEnabled}
        onClick={() => handleSessionClick(session)}
      />
    )),
    <HelpAndInfoContainer key={displaySessions.length}>
      <HelpAndInfoBox
        title="Don't worry, more sessions are on the way"
        description="Check with your ministry leader for more details."
      />
    </HelpAndInfoContainer>,
  ];

  return (
    <ContainerFluid data-qa-hook="carouselItem" style={{ marginBottom: '48px' }}>
      <SectionHeader title={curriculum.brand} subtitle={curriculum.name} />
      <Carousel
        initialFirstItem={carouselIndex}
        breakPoints={breakPoints}
        renderArrow={carouselControls}
        pagination={false}
      >
        {carouselItems}
      </Carousel>
      {modal}
    </ContainerFluid>
  );
}

CurriculumCarousel.propTypes = {
  curriculum: PropTypes.shape({
    brandCode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      scheduledDate: PropTypes.string.isRequired,
    })
  ),
};
