import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorMessage from '../components/ErrorMessage';
import { Container, PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import useOrg from '../hooks/useOrg';
import AngularServiceProvider from '../services/AngularServiceProvider';
import windowService from '../services/windowService';
import { localStorage } from '../utils/storageUtils';
import { InviteGroup } from './models/inviteSettings';
import { AddSetting } from './components/AddSetting';

type UrlParams = {
  inviteId: string;
  settingsId: string;
  orgId: string;
  type: string;
};

type OrgStatus = {
  org?: { groups: OrgGroup[] };
  isLoading: boolean;
  error?: Error;
};

type OrgGroup = InviteGroup & { curriculum?: object };

export const AddGroupsToInvite = () => {
  const [{ org, isLoading, error }, setOrgStatus] = useState<OrgStatus>({ isLoading: true });
  const { orgId, type } = useParams<UrlParams>();

  useOrg(setOrgStatus);

  const inviteSettingsPath = `#/invite-settings/${orgId}/${type}`;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    { name: 'Invite Settings', route: inviteSettingsPath },
    { name: 'Add Groups' },
  ];

  const onGroupsSelected = (selectedItems: InviteGroup[]) => {
    // @ts-expect-error
    AngularServiceProvider.get('$localStorage').inviteGroups = selectedItems;
    windowService.redirectTo(inviteSettingsPath);
  };

  return (
    <div className="mb-64">
      <Breadcrumbs crumbs={crumbs} />

      <Container>
        <PageTitle className="mt-32 mb-32">
          <h1>Add Groups to Invite</h1>
          <p>Select the groups you would like to attach to your invite</p>
        </PageTitle>

        {isLoading ? (
          <LoadingState />
        ) : error || !org ? (
          <ErrorMessage />
        ) : (
          <AddSetting
            settingLabel="Groups"
            items={org?.groups.filter((group: OrgGroup) => !group.curriculum) || []}
            preselectedItems={localStorage.getItem('ngStorage-inviteGroups') || []}
            onSubmit={onGroupsSelected}
          />
        )}
      </Container>
    </div>
  );
};
