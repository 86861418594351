import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Container } from '../../components/Layout';
import { SessionDateHeader, CalendarDate, SessionDetails } from '../../components/SessionElements';
import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import CalendarDateIcon from '../../components/CalendarDateIcon';
import SessionTimelineBuilder from './SessionTimelineBuilder';
import { ButtonGroup, TileButton } from '../../components/Buttons';
import CopySessionModal from './CopySessionModal';
import curriculumService, { getAvailableThroughMessage } from '../../services/curriculumService';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';
import { SessionTimelineProvider } from '../../contexts/SessionTimelineContext';
import SessionTimelinePrintStyles from './SessionTimelinePrintStyles';
import useCurriculum from '../../hooks/useCurriculum';
import useModal from '../../hooks/useModal';

export default function ManageSessionTimeline() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId } = useParams();
  const [data, setData] = useState({ isLoading: true, isError: false });

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: 'Session Calendar',
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`,
    },
    { name: 'Session' },
  ];

  const redirectToSessionTimeline = (sessionId, timelineId) => {
    windowService.redirectTo(
      `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session/${sessionId}/timeline/${timelineId}`
    );
  };

  const redirectToEditSession = () =>
    windowService.redirectTo(
      `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session/${sessionId}/timeline/${timelineId}/edit`
    );

  const copySession = () => openModal('copySession');

  const [modal, openModal] = useModal((type, payload, dismissModal) =>
    type ? (
      <CopySessionModal
        fromSession={data.session}
        fromTimeline={data.timeline}
        handleSessionAdded={redirectToSessionTimeline}
        handleDismiss={dismissModal}
      />
    ) : null
  );

  useEffect(() => {
    Promise.all([
      curriculumService.getSessionById(sessionId, true),
      sessionTimelineService.getSessionTimeline(timelineId),
      curriculumService.getIndividualCurriculum(curriculumId),
    ])
      .then(([session, timeline, curriculum]) =>
        setData({ session, timeline, curriculum, isLoading: false, isError: false })
      )
      .catch(error => {
        console.error(error);
        setData({ ...data, isLoading: false, isError: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SessionTimelinePrintStyles />

      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="sessionBuilderView">
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <SessionDateHeader>
            <CalendarDate>
              <CalendarDateIcon
                month={format(new Date(data.session.useDate), 'MMM')}
                day={format(new Date(data.session.useDate), 'd')}
                large={true}
              />
            </CalendarDate>
            <SessionDetails>
              <h2 data-qa-hook="sessionName">{data.session.name}</h2>
              <p data-qa-hook="availableThroughDate">
                {getAvailableThroughMessage(data.session, new Date(data.session.publishEndDate), 'MMMM d, yyyy')}
              </p>
            </SessionDetails>
            <ButtonGroup>
              <TileButton onClick={redirectToEditSession}>
                <i className="fas fa-pen"></i> Edit Session
              </TileButton>

              {window.featureToggles.get('COPY_SESSION_TIMELINE') && (
                <TileButton onClick={copySession}>
                  <i className="fas fa-copy"></i>Copy Session
                </TileButton>
              )}
            </ButtonGroup>
          </SessionDateHeader>
        )}
      </Container>
      {!data.isLoading && (
        <SessionTimelineProvider timeline={data.timeline} curriculum={data.curriculum} session={data.session}>
          <SessionTimelineBuilder />
        </SessionTimelineProvider>
      )}

      {modal}
    </>
  );
}
