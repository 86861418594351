import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Color, Breakpoints } from '../../StyleGuide';
import Overlay from '../../components/Overlay';
import { ButtonGroup, Button, PrimaryButton } from '../../components/Buttons';
import { FormField } from '../../components/FormElements';
import { SessionCard } from '../../components/SessionElements';
import { PageTitle } from '../../components/Layout';
import LoadingIndicator from '../../components/LoadingIndicator';
import sessionTimelineService from '../../services/sessionTimelineService';
import { useUser } from '../../authentication';
import { isNoEffect } from '../../utils/apiUtils';

const OverlayContainer = styled.div`
  margin-top: ${Grid._5}; // account for the close button on mobile
  padding: ${Grid._4};
  width: 100vw;

  .session-card-container {
    padding: 0;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin-top: 0;
    padding: ${Grid._6};
    width: 85vw;
    max-width: 720px;
    height: auto;
  }
`;

export const TimelineList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditableTimelineItem = styled.div.attrs({
  'data-qa-hook': 'leaderGuide',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Grid._4};
  border-top: 1px solid ${Color.Blue._15};

  &:last-child {
    border-bottom: 1px solid ${Color.Blue._15};
  }

  &:hover {
    background: ${Color.Gray._05};
    cursor: pointer;

    h5 {
      text-decoration: underline;
    }
  }
`;

const TimelineFormContainer = styled.div`
  ${PageTitle} {
    margin: ${Grid._5} 0 ${Grid._5} 0;
  }

  ${ButtonGroup} {
    justify-content: flex-end;
  }
`;

const TimelineNameForm = ({ currentName = '', leaderGuideAction = 'Add', onSubmit, onCancel }) => {
  return (
    <Formik
      initialValues={{ name: currentName }}
      validationSchema={Yup.object({
        name: Yup.string().required('A name is required'),
      })}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FormField label="Name" name="name" type="text" placeholder="Enter Name" value={values.name} />
          <ButtonGroup>
            <PrimaryButton type="submit" operating={isSubmitting} disabled={isSubmitting}>
              {leaderGuideAction} Leader Guide
            </PrimaryButton>
            <Button onClick={onCancel}>Cancel</Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

TimelineNameForm.propTypes = {
  currentName: PropTypes.string,
  leaderGuideAction: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const RenameTimelineForm = ({ timeline, onSuccess, onError, onCancel }) => {
  const user = useUser();

  const onSubmit = values => {
    return sessionTimelineService
      .renameCustomizedTimeline(
        values.name,
        timeline.originalTimelineId,
        timeline.id,
        user.lastSelectedAccount,
        user.userId
      )
      .then(onSuccess)
      .catch(error => {
        if (isNoEffect(error)) return onSuccess();
        onError(error);
      });
  };

  return (
    <TimelineFormContainer>
      <PageTitle className="mt-20 mb-20">
        <h2>Rename Leader Guide</h2>
        <p>Edit the name of {timeline.name}</p>
      </PageTitle>
      <TimelineNameForm
        currentName={timeline.name}
        leaderGuideAction="Update"
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </TimelineFormContainer>
  );
};

RenameTimelineForm.propTypes = {
  timeline: PropTypes.shape({
    id: PropTypes.string.isRequired,
    originalTimelineId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const CopyTimelineForm = ({ timeline, onSuccess, onError, onCancel }) => {
  const user = useUser();

  const onSubmit = values => {
    return sessionTimelineService
      .copyCustomizedTimeline(
        values.name,
        timeline.originalTimelineId,
        timeline.id,
        user.lastSelectedAccount,
        user.userId
      )
      .then(onSuccess)
      .catch(onError);
  };

  return (
    <TimelineFormContainer>
      <PageTitle className="mt-20 mb-20">
        <h2>Copy Leader Guide</h2>
        <p>This will create a copy of {timeline.name}</p>
      </PageTitle>
      <TimelineNameForm onSubmit={onSubmit} onCancel={onCancel} />
    </TimelineFormContainer>
  );
};

CopyTimelineForm.propTypes = {
  timeline: PropTypes.shape({
    id: PropTypes.string.isRequired,
    originalTimelineId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const CreateTimelineForm = ({ originalTimelineId, onSuccess, onError, onCancel }) => {
  const user = useUser();

  const onSubmit = values => {
    return sessionTimelineService
      .createCustomizedTimeline(values.name, originalTimelineId, user.lastSelectedAccount, user.userId)
      .then(onSuccess)
      .catch(onError);
  };

  return (
    <TimelineFormContainer>
      <PageTitle className="mt-20 mb-20">
        <h2>Add Leader Guide</h2>
        <p>This will create a new leader guide</p>
      </PageTitle>
      <TimelineNameForm onSubmit={onSubmit} onCancel={onCancel} />
    </TimelineFormContainer>
  );
};

CreateTimelineForm.propTypes = {
  originalTimelineId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const TimelinesOverlay = ({ session, handleDismiss, children }) => {
  return (
    <Overlay dismissHandler={handleDismiss}>
      <Overlay.CloseButton />
      <OverlayContainer>
        <SessionCard session={session} />
        {children}
      </OverlayContainer>
    </Overlay>
  );
};

TimelinesOverlay.propTypes = {
  session: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export const DefaultLeaderGuideItem = ({ timelineId, onSuccess, onError }) => {
  const user = useUser();
  const [isCreating, setIsCreating] = useState(false);

  const createCustomizedTimeline = () => {
    setIsCreating(true);

    return sessionTimelineService
      .createCustomizedTimeline('Leader Guide', timelineId, user.lastSelectedAccount, user.userId)
      .then(({ id, timelineId: originalTimelineId }) => ({ id, originalTimelineId }))
      .then(onSuccess)
      .catch(onError);
  };

  return isCreating ? (
    <LoadingIndicator />
  ) : (
    <EditableTimelineItem onClick={createCustomizedTimeline}>
      <div>
        <h5>Leader Guide</h5>
      </div>
    </EditableTimelineItem>
  );
};

DefaultLeaderGuideItem.propTypes = {
  timelineId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
