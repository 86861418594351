import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Border, Type } from '../StyleGuide';
import WhiteLabel from '../filters/WhiteLabel';
import useCurriculum from '../hooks/useCurriculum';

export const CurriculumItem = styled.div`
  align-items: center;
  padding: ${Grid._5} 0;
  transition: all 0.15s ease-in-out;
  min-height: 119px;
  border-bottom: 1px solid ${Color.Blue._15};

  &:hover {
    background: ${Color.white};
    cursor: pointer;
    h4 {
      text-decoration: underline;
    }
  }

  div:last-child {
    align-items: center;

    h4 {
      color: ${Type.Color.dark};
      font-size: ${Type.Scale._3};
      font-weight: ${Type.Weight.semibold};
      margin: 0;
    }

    p {
      color: ${Type.Color.medium};
      font-weight: ${Type.Weight.normal};
      margin: 0;
    }
  }
`;

const CurriculumImageContainer = styled.div`
  background: ${props => (props.background ? props.background : Color.Gray._20)};
  border-radius: ${Border.radius};

  height: ${props => props.height || '102px'};
  width: ${props => props.width || '212px'};
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
`;

const TrainingImageContainer = styled.div`
  display: grid;
  place-content: center;

  background: ${Color.Gray._20};
  border-radius: ${Border.radius};

  height: ${props => props.height || '102px'};
  width: ${props => props.width || '212px'};
  overflow: hidden;

  img {
    height: 36px;
    max-width: 128px;
  }
`;

export const CurriculumImage = props => {
  const { brand } = useCurriculum(props.brandCode);

  return (
    <CurriculumImageContainer className="curriculum-element--curriculum-image" {...props} background={brand.background}>
      <img alt={brand.name} src={brand.imgSrc} />
    </CurriculumImageContainer>
  );
};
export const TrainingImage = props => (
  <TrainingImageContainer className="curriculum-element--training-image" {...props}>
    <img alt={WhiteLabel.name} src={WhiteLabel.logoSmSrc || WhiteLabel.logoSrc} />
  </TrainingImageContainer>
);
