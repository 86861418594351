import { Status } from './status';

export type InvitationType = 'email' | 'code';
export type InvitationTypeDisplayName = 'Email' | 'Easy Link';

export const InvitationTypeDisplayNameMap: Record<InvitationType, InvitationTypeDisplayName> = {
  email: 'Email',
  code: 'Easy Link',
};

export type Invitee = {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  status: Status;
  redeemedAs?: string;
};

export type Redemption = {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
};

export type Invite = {
  id: string;
  name: string;
  email: string;
  type: InvitationType;
  orgId: string;
  active: boolean;
  redemptionCount: number;
  redemptionLimit: number | null;
  workflowId?: string;
  searchData?: string;
};

export type EmailInvite = Invite & {
  type: 'email';
  invitees: Invitee[];
};

export type EasyLinkInvite = Invite & {
  type: 'code';
  membershipCode: string;
  redemptions: Redemption[];
  redemptionLink: string;
};

export const isEmailInvite = (invite: Invite): invite is EmailInvite => invite.type === 'email';
export const isEasyLinkInvite = (invite: Invite): invite is EasyLinkInvite => invite.type === 'code';
