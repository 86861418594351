import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import BrightcoveVideoPlayer from '../components/BrightcoveVideoPlayer';
import vs from '../services/VideoService';
import brightcoveTrackingService from '../services/BrightcoveTrackingService';
import uuid from '../services/uuid';
import { handleError } from '../utils/apiUtils';

export default function VideoPlayer({
  defaultRate = 1,
  defaultAudioOnly = false,
  defaultAutoplay = false,
  autoExitFullscreen = true,
  onExitFullscreen,
  onProgress,
  ...props
}) {
  const savedVideoPlayerRate = localStorage.getItem('videoPlayerRate');
  const savedVideoPlayerAudioOnly = localStorage.getItem('videoPlayerAudioOnly');

  const init_audioOnly = savedVideoPlayerAudioOnly ? savedVideoPlayerAudioOnly !== 'false' : defaultAudioOnly;
  const init_rate = savedVideoPlayerRate ?? defaultRate;
  const init_autoplay = defaultAutoplay;

  // Create Hooks
  const [audioOnly, setAudioOnly] = useState(init_audioOnly);
  const [progress, setProgress] = useState(0);
  const [autoplay, setAutoplay] = useState(init_autoplay);
  const [rate, setRate] = useState(init_rate);
  const [fallback, setFallback] = useState();
  const [poster, setPoster] = useState();
  const [tracking_videoId, setTracking_VideoId] = useState();
  const [videoInSeconds, setVideoInSeconds] = useState();
  const [brightcoveVideo, setBrightcoveVideo] = useState();
  let tracking_duration = 0,
    tracking_progress = 0,
    bcSessionTrackingId = uuid.generate();
  let isInitialProgressSet = false;

  useEffect(() => {
    const load = async () => {
      Promise.all([vs.getProgress(props.videoId), vs.getVideoPlaybackById(props.videoId)])
        .then(([videoProgress, video]) => {
          const videoFallback = video.videoSources.mp4.https;
          const videoPoster = video.posterSources.https;

          setTracking_VideoId(video.videoId);
          brightcoveTrackingService.playerLoad(video.videoId, bcSessionTrackingId);
          setFallback(videoFallback);
          setPoster(videoPoster);
          setProgress(videoProgress);
          setBrightcoveVideo(video);
        })
        .catch(handleError)
        .finally(() => props.setIsLoading(false));
    };
    load();
  }, []);

  useEffect(() => {
    if (onProgress)
      onProgress({
        percentComplete: progress / videoInSeconds,
        elapsedSeconds: progress,
        totalDuration: videoInSeconds,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const onTimeUpdated = currentTime => {
    let t = Math.floor(currentTime);
    setProgress(t);
    if (t !== 0 && t % 15 === 0 && progress !== t) {
      tracking_progress = t;
      vs.saveProgress(props.userId, props.videoId, t);
      brightcoveTrackingService.videoEngagement(tracking_videoId, bcSessionTrackingId, t - 15, t, tracking_duration);
    }
  };

  const audioOnlyToggle = ao => {
    setAutoplay(true);
    setAudioOnly(prev => !prev);
    localStorage.setItem('videoPlayerAudioOnly', ao);
    brightcoveTrackingService.videoView(tracking_videoId, bcSessionTrackingId);
  };

  const rateChange = r => {
    setRate(r);
    localStorage.setItem('videoPlayerRate', r);
  };

  /**
   * Sets the initial video progress to the last point we stored. Because of an issue with video-js
   * where progress on a video on a mobile device cannot be set until the video has retrieved playable data,
   * this will be called multiple times throughout the lifecycle of the video but should
   * only successfully set the progress of the video once. (see ASSESS 7070)
   */
  const setInitialProgress = player => {
    if (!isInitialProgressSet) {
      if (!progress || player.currentTime() >= progress) {
        isInitialProgressSet = true;
      } else {
        player.currentTime(progress);
        player.controls(true);

        // due to the mobile bug, we may get to this point but the currentTime
        // is still not properly set by the library so we need to try again
        // at a different point in the video lifecycle
        isInitialProgressSet = player.currentTime() >= progress;
      }
    }

    return isInitialProgressSet;
  };

  // Create audio/video toggle button on controlbar
  const audioVideoMenu = player => {
    const ButtonComponent = videojs.getComponent('Button');
    const settingsCog = new ButtonComponent(player);

    settingsCog.addClass('vjs-icon-cog');
    settingsCog.addClass('vjs-menu-button-popup');
    settingsCog.addClass('vjs-menu-button');

    player.controlBar.addChild(settingsCog, {}, 12);

    const settingsButton = document.querySelector('.vjs-icon-cog');
    document.querySelector('.vjs-fullscreen-control').style.display = 'block';

    let menuWrapper = document.createElement('div');
    const ul = document.createElement('ul');
    const videoLi = document.createElement('li');
    const audioLi = document.createElement('li');
    videoLi.appendChild(document.createTextNode('Video'));
    audioLi.appendChild(document.createTextNode('Audio Only'));
    ul.append(videoLi, audioLi);

    menuWrapper.classList.add('vjs-menu');
    ul.classList.add('vjs-menu-content');
    videoLi.classList.add('vjs-menu-item');
    audioLi.classList.add('vjs-menu-item');
    if (audioOnly) {
      audioLi.classList.add('vjs-selected');
      // classname that allows the "Audio Only" banner to display.
      player.addClass('vjs-audio');
    } else {
      videoLi.classList.add('vjs-selected');
    }
    menuWrapper.appendChild(ul);
    player.removeClass('bc-player-default_default');

    videoLi.addEventListener('click', () => {
      audioOnlyToggle(false);
      videoLi.classList.add('vjs-selected');
      audioLi.classList.remove('vjs-selected');
      player.audioOnlyMode(false);

      player.removeClass('vjs-audio');
    });
    audioLi.addEventListener('click', () => {
      audioOnlyToggle(true);
      audioLi.classList.add('vjs-selected');
      videoLi.classList.remove('vjs-selected');
      player.audioOnlyMode(true);

      player.addClass('vjs-audio');
    });

    settingsButton.appendChild(menuWrapper);

    settingsButton.addEventListener('mouseover', () => {
      settingsButton.classList.add('vjs-hover');
    });

    settingsButton.addEventListener('mouseout', () => {
      settingsButton.classList.remove('vjs-hover');
    });

    // toggle audio/video mode on settings button click
    settingsButton.addEventListener('touchstart', () => {
      audioOnlyToggle(!audioOnly);

      if (document.querySelector('.vjs-audio')) {
        player.audioOnlyMode(false);
        player.removeClass('vjs-audio');
      } else {
        player.audioOnlyMode(true);
        player.addClass('vjs-audio');
      }
    });
  };

  const setup = () => {
    let myPlayer;
    let seekingEvent = false;

    try {
      const id = `video-${brightcoveVideo.videoId}`;
      myPlayer = videojs(id);

      audioVideoMenu(myPlayer);

      myPlayer.on('loadedmetadata', function () {
        tracking_duration = parseInt(myPlayer.duration(), 10);
        const minutes = parseInt(myPlayer.duration() / 60, 10);
        setVideoInSeconds(minutes * 60);
        if (minutes < 2 && myPlayer.src() !== fallback) {
          myPlayer.src(fallback);
        }
        if (rate !== undefined) {
          myPlayer.playbackRate(rate);
        }
        brightcoveTrackingService.videoImpression(tracking_videoId, bcSessionTrackingId);
      });
      myPlayer.on('canplay', function () {
        if (seekingEvent) {
          seekingEvent = false;
        }
      });
      myPlayer.on('seeking', function () {
        seekingEvent = true;
      });
      myPlayer.on('timeupdate', function () {
        onTimeUpdated(myPlayer.currentTime());
      });
      myPlayer.on('canplaythrough', function () {
        if (setInitialProgress(myPlayer)) myPlayer.off('canplaythrough');
      });
      myPlayer.on('play', function () {
        brightcoveTrackingService.playRequest(tracking_videoId, bcSessionTrackingId);
        brightcoveTrackingService.videoView(tracking_videoId, bcSessionTrackingId);
      });
      myPlayer.on('ended', function () {
        if (myPlayer.isFullscreen()) {
          myPlayer.exitFullscreen();
          myPlayer.exitFullWindow();
        }

        brightcoveTrackingService.videoEngagement(
          tracking_videoId,
          bcSessionTrackingId,
          tracking_progress,
          tracking_duration,
          tracking_duration
        );

        vs.saveProgress(props.userId, props.videoId, 0);
        setProgress(0);
        props.onEnd();
      });
      myPlayer.on('ratechange', function () {
        rateChange(myPlayer.playbackRate());
      });

      myPlayer.on('fullscreenchange', function () {
        !myPlayer.isFullscreen() && onExitFullscreen && onExitFullscreen();
      });
    } catch (e) {
      console.error('Video error: ' + e);
    }
    return myPlayer;
  };

  return (
    <div>
      {brightcoveVideo?.videoId && (
        <BrightcoveVideoPlayer
          poster={poster}
          videoId={brightcoveVideo.videoId}
          accountId={brightcoveVideo.accountId}
          audioOnly={audioOnly}
          autoPlay={autoplay}
          setup={setup}
        />
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  defaultRate: PropTypes.number,
  defaultAudioOnly: PropTypes.bool,
  defaultAutoplay: PropTypes.bool,
  autoExitFullscreen: PropTypes.bool,
  onExitFullscreen: PropTypes.func,
  onProgress: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
  onEnd: PropTypes.func,
  userId: PropTypes.string,
};
