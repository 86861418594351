import React, { useState, useEffect, ChangeEvent } from 'react';
import { Container } from '../components/Layout';
import styled from 'styled-components';
import workflowService from '../services/workflowService';
import alertService from '../services/AlertService';
import { useUser } from '../authentication';
import useModal from '../hooks/useModal';
import { useBuildFilter } from './hooks/useBuildFilter';
import { SelectMultiple, TextFilterInput } from '../components/FormElements';
import { ButtonGroup, PrimaryButton, SortButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { ListItem } from './components/ListItem';
import { Breakpoints, Grid } from '../StyleGuide';
import ActiveFilters from '../components/ActiveFilters';
import { AddPathwayModal } from './components/modals/AddPathwayModal';
import EmptyState from '../components/EmptyState';
import { handleError } from '../utils/apiUtils';
import { RemoveTrainingModal } from './components/modals/RemoveTrainingModal';
import { TrainingEntity } from './models/training';

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: ${Grid._4};
  align-items: end;
  margin-bottom: ${Grid._6};

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SortButtonGroup = styled(ButtonGroup)`
  margin-bottom: ${Grid._3};
`;

type Pathway = TrainingEntity & {};

type Props = {
  ownerId: string;
};

export const PathwayList = ({ ownerId }: Props) => {
  const user = useUser();
  const [data, setData] = useState<{ pathways: Pathway[]; isError: boolean; isLoading: boolean }>({
    pathways: [],
    isError: false,
    isLoading: true,
  });

  const { filteredItems, multiFilter, sortFilter } = useBuildFilter<Pathway>(data.pathways);
  const { filters } = multiFilter;

  useEffect(() => {
    if (ownerId) {
      setData(prevState => ({
        ...prevState,
        isLoading: true,
      }));
      workflowService
        .getPathways(ownerId)
        .then((response: Pathway[]) => {
          setData(prevState => ({
            ...prevState,
            pathways: response,
            isLoading: false,
          }));
        })
        .catch((error: Error) => {
          handleError(error);
          setData(prevState => ({
            ...prevState,
            isError: true,
            isLoading: false,
          }));
        });
    }
  }, [ownerId]);

  const removePathway = (pathway: Pathway) =>
    workflowService
      .removePathway(pathway.id, ownerId, user?.userId)
      .then(() => {
        const filteredPathways = data.pathways.filter(path => path.id !== pathway.id);
        setData(prevState => ({
          ...prevState,
          pathways: filteredPathways,
        }));
        alertService.show('Pathway Removed');
      })
      .catch(handleError)
      .finally(dismissModal);

  const [modal, openModal, dismissModal] = useModal(
    (type: string, payload: Pathway | undefined, dismissModal: () => void) => {
      if (type === 'addPathway') return <AddPathwayModal ownerId={ownerId} closeModal={dismissModal} />;
      if (type === 'removePathway' && payload)
        return (
          <RemoveTrainingModal
            name={payload?.name || ''}
            type="pathway"
            handleSubmit={() => removePathway(payload)}
            handleDismiss={dismissModal}
          />
        );
      return null;
    }
  );

  const activeFilters = {};
  // @ts-expect-error
  // TODO: Fix this
  if (!filters.status.isEmpty) activeFilters['Status'] = filters.status.reset;

  const showActiveFilters = !!Object.keys(activeFilters).length || !!filters.query?.value;

  return (
    <>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </ErrorMessage>
      ) : (
        <Container>
          <OptionsContainer>
            <SelectContainer data-qa-hook="workflowsStatusFilter">
              <SelectMultiple
                label="Status"
                options={['Show Published', 'Show Not Published']}
                selections={filters.status.values}
                onSelection={filters.status.update}
                data-qa-hook="taskListsStatusFilter"
              />
            </SelectContainer>
            <TextFilterInput
              id="pathwaysSearch"
              data-qa-hook="workflowsSearchFilter"
              label="Search"
              value={filters.query.value}
              onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => filters.query.update(e.target.value)}
              placeholder="Search Pathways"
              clearInput={() => filters.query.update('')}
            />
            <div className="primary-action">
              <PrimaryButton onClick={() => openModal('addPathway')}>Add Pathway</PrimaryButton>
            </div>
          </OptionsContainer>
          {showActiveFilters && <ActiveFilters count={filteredItems?.length} filters={activeFilters} />}

          <SortButtonGroup>
            <SortButton
              sortActive={sortFilter.method === 'name'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('name')}
            >
              Name
            </SortButton>
            <SortButton
              sortActive={sortFilter.method === 'lastEdited'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('lastEdited')}
            >
              Last Edited
            </SortButton>
          </SortButtonGroup>

          {filteredItems?.length === 0 ? (
            filters.query?.value ? (
              <EmptyState
                icon="fa-search"
                title="Your search does not have any matches"
                description="Please try another search."
              />
            ) : (
              <EmptyState title="No Pathways Added" description="To add a pathway use the Add button." />
            )
          ) : (
            filteredItems.map((pathway: Pathway) => (
              <ListItem
                key={pathway.id}
                item={pathway}
                type="pathway"
                onRemoveClick={() => openModal('removePathway', pathway)}
              />
            ))
          )}
          {modal}
        </Container>
      )}
    </>
  );
};
