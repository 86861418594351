import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { localStorage } from '../../utils/storageUtils';
import { getInviteSettings } from '../api/getInviteSettings';
import { setOptionsForInvite } from '../api/setOptionsForInvite';
import { Invite } from '../models/invite';
import { InviteGroup, InviteRole, InviteSettings, InviteTraining } from '../models/inviteSettings';
import AngularServiceProvider from '../../services/AngularServiceProvider';

type State = {
  isLoading: boolean;
  invite?: Invite;
  inviteSettings: InviteSettings;
  error?: AxiosError;
};

export const useInviteSettings = (inviteId?: string, inviteSettingsId?: string, userId?: string) => {
  const [data, setData] = useState<State>({
    isLoading: true,
    inviteSettings: {
      roles: [],
      licenses: [],
      groups: [],
      trainings: [],
    },
  });

  const updateSetting = useCallback(
    (setting: keyof InviteSettings, value: InviteTraining[] | InviteGroup[] | InviteRole[]) => {
      const updatedSettings = { ...data.inviteSettings, [setting]: value };

      if (inviteId) {
        return setOptionsForInvite(inviteId, inviteSettingsId, updatedSettings, userId).then(() => {
          setData(prev => ({ ...prev, inviteSettings: updatedSettings }));
        });
      }

      switch (setting) {
        case 'roles':
          // @ts-expect-error
          AngularServiceProvider.get('$localStorage').inviteRoles = value;
          break;
        case 'groups':
          // @ts-expect-error
          AngularServiceProvider.get('$localStorage').inviteGroups = value;
          break;
        case 'trainings':
          // @ts-expect-error
          AngularServiceProvider.get('$localStorage').inviteTraining = value;
          break;
      }

      setData(prev => ({ ...prev, inviteSettings: updatedSettings }));
      return Promise.resolve();
    },
    [data.inviteSettings, inviteId, inviteSettingsId]
  );

  useEffect(() => {
    if (!inviteId || !inviteSettingsId) {
      const storedSettings = {
        roles: localStorage.getItem('ngStorage-inviteRoles') as InviteRole[],
        licenses: [],
        groups: localStorage.getItem('ngStorage-inviteGroups') as InviteGroup[],
        trainings: localStorage.getItem('ngStorage-inviteTraining') as InviteTraining[],
      };

      return setData({ inviteSettings: storedSettings, isLoading: false });
    }

    getInviteSettings(inviteSettingsId)
      .then(inviteSettings => {
        setData({ isLoading: false, inviteSettings });
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isLoading: false, error }));
      });
  }, []);

  return {
    ...data,
    updateSetting,
  };
};
