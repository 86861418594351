import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Grid, Border, Color, Type } from '../StyleGuide';
import uuid from '../services/uuid';
import { getFileExtension } from '../utils/fileUtils';
import uploadService from '../services/uploadService';
import ProgressBar from '../components/ProgressBar';
import ErrorMessage from '../components/ErrorMessage';
import { FullWidthButton } from '../components/Buttons';
import { FileTypeMap } from '../utils/fileUtils';
import { handleError } from '../utils/apiUtils';

const getStatusColor = props => {
  if (props.isDragAccept) {
    return `${Color.Green._50}`;
  }
  if (props.isDragReject) {
    return `${Color.Orange._50}`;
  }
  if (props.isDragActive) {
    return `${Color.Secondary._50}`;
  }
  return `${Color.Blue._20}`;
};

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: ${Grid._3};
  padding: ${Grid._7};
  border-width: 2px;
  border-radius: ${Border.radius};
  border-color: ${props => getStatusColor(props)};
  border-style: dashed;
  background-color: none;
  color: ${Type.Color.dark};
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
  p {
    color: ${Type.Color.dark};
    margin: 0;
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  margin: ${Grid._4} auto;
  border-radius: ${Border.radius};
  background: ${Color.Secondary._80};
  color: white;
  font-size: ${Type.Scale._3};
  span {
    padding: ${Grid._5};
    flex-grow: 1;
    word-break: break-all;
  }
  i {
    padding-right: ${Grid._5};
    font-size: ${Type.Scale._5};
    cursor: pointer;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const buildAcceptsData = (accepts, typeMap) => ({
  mimeTypes: accepts.map(type => typeMap[type]).join(','),
  message: `We accept ${accepts.reduce(
    (msg, type, i) =>
      msg
        .concat(accepts.length > 1 && i === accepts.length - 1 ? ' and ' : '')
        .concat(type)
        .concat(i < accepts.length - 2 ? ', ' : ''),
    ''
  )} files`,
});

const ErrorReason = Object.freeze({
  EXCEEDS_MAXSIZE: 'exceeds_maxsize',
  INVALID_TYPE: 'invalid_type',
});

export default function FileUploader({
  userId,
  orgId,
  initialFile,
  onFileUploaded,
  onFileRemoved,
  uploadLocation,
  accepts,
}) {
  const [file, setFile] = useState(initialFile);
  const [errorReason, setErrorReason] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const acceptsData = buildAcceptsData(accepts, FileTypeMap);
  const maxSize = 8589934592; //8gb- increasing this for videos, we may want to consider different maxSizes per media type in the future

  const updateFile = file => {
    setFile(file);
    !!file && onFileUploaded(file);
  };

  const removeFile = () => {
    setFile();
    setUploadProgress(null);
    onFileRemoved();
  };

  const cancelUpload = () => {
    uploadService.cancel('Operation canceled by the user.');
    removeFile();
  };

  const getUploadLocation = (location, file) => {
    if (typeof location === 'string') return location;
    if (typeof location === 'function') return location(file);
  };

  const onProgress = ({ loaded, total }) => setUploadProgress(Math.floor((loaded / total) * 100));

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    //We only care about the first file if multiple are added
    if (rejectedFiles.length) {
      console.warn('Rejected File: ', rejectedFiles[0]);
      updateFile();
      setErrorReason(rejectedFiles[0].size > maxSize ? ErrorReason.EXCEEDS_MAXSIZE : ErrorReason.INVALID_TYPE);
    } else {
      setErrorReason();

      const acceptedFile = acceptedFiles[0];

      acceptedFile.id = uuid.generate();
      acceptedFile.extension = getFileExtension(acceptedFile.name);

      const location = getUploadLocation(uploadLocation, acceptedFile);

      const promise = location
        ? uploadService.upload(location, `${acceptedFile.id}.${acceptedFile.extension}`, acceptedFile, onProgress, true)
        : uploadService.uploadFile(acceptedFile.id, orgId, acceptedFile.name, acceptedFile, true, userId, onProgress);

      promise
        .then(() => updateFile(acceptedFile))
        .catch(error => {
          if (error.code === 'ERR_CANCELED') {
            return console.log(error.message); // eslint-disable-line no-console
          }

          handleError(error);
        });
    }
  });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxSize,
    accept: acceptsData.mimeTypes,
  });

  return (
    <>
      {uploadProgress !== null && (
        <Container>
          {uploadProgress < 100 && (
            <p className="text-color-medium">{uploadProgress}% - Please wait for file to upload</p>
          )}
          {uploadProgress == 100 && <p className="text-color-medium">Upload complete</p>}
          <ProgressBar progress={uploadProgress} className="tl-progress-bar" style={{ margin: '24px auto' }} />
          {uploadProgress < 100 && <FullWidthButton onClick={cancelUpload}>Cancel</FullWidthButton>}
        </Container>
      )}

      {!file && uploadProgress === null && (
        <>
          <DropZone data-qa-hook="fileUploader" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the file here</p> : <p>Click to select a file or drag and drop a file</p>}
          </DropZone>
          <p data-qa-hook="acceptsMessage" className="text-color-medium" style={{ textAlign: 'center' }}>
            {acceptsData.message}
          </p>
        </>
      )}

      {!!file && (
        <Container>
          <File>
            <span>{file.name}</span>
            <i className="icon ion-ios-trash-outline" onClick={removeFile}></i>
          </File>
        </Container>
      )}

      {errorReason === ErrorReason.EXCEEDS_MAXSIZE && <ErrorMessage>File is too large - 8 GB max size</ErrorMessage>}
      {errorReason === ErrorReason.INVALID_TYPE && <ErrorMessage>File type is invalid</ErrorMessage>}
    </>
  );
}

FileUploader.defaultProps = {
  accepts: Object.keys(FileTypeMap),
};

FileUploader.propTypes = {
  userId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  initialFile: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  uploadLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  accepts: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(FileTypeMap))),
};
