import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const ProgressBar = styled.div`
  background: ${props => (props.isWorkFlow ? Color.Blue._10 : Color.Green._10)};
  padding: 0;
  margin: 0;
  height: ${Grid._3};
  position: relative;
  border-radius: ${Border.radius};
  :before {
    background: ${props => (props.isWorkFlow ? Color.Blue._50 : Color.Green._50)};
    display: inline-block;
    content: '';
    top: 0;
    left: 0;
    height: ${Grid._3};
    width: ${props => (props.progress ? props.progress : 0)}%;
    position: absolute;
    border-radius: ${Border.radius};
    transition: width 0.3s ease;
  }
`;

export default ProgressBar;
