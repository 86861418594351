import React from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';

interface Props {
  src: string;
  size: string;
}

const CircularProfileImage = styled.img<Props>`
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: ${props => (props.size ? props.size : Grid._10)} !important;
  height: ${props => (props.size ? props.size : Grid._10)} !important;
  object-fit: cover;
`;

export default function ProfileImage({ src, size }: Props) {
  return src ? (
    <CircularProfileImage src={src} size={size} />
  ) : (
    <span className="fas fa-user-circle" style={{ fontSize: size, width: size, height: size }}></span>
  );
}
