import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Border, Breakpoints, Type } from '../StyleGuide';
import { MediumButton } from '../components/Buttons';
import ProfileImage from '../groups/ProfileImage';
import limitFilter from '../filters/limit-filter';

const Table = styled.table`
  width: 100%;
  transition: opacity 0.25s ease-out;
  opacity: 1;
  margin-top: ${Grid._5};
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    margin-top: 0;
  }
  &.isUpdating {
    opacity: 0.8;
  }
  tbody {
    display: grid;
    grid-gap: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }
  tr {
    order: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid ${Color.Blue._15};
    border-radius: ${Border.radius};
    color: ${Type.Color.medium};
    align-items: center;
    padding: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      display: grid;
      grid-template-columns: 48px 1fr 1fr 168px;
      grid-column-gap: ${Grid._5};
      border-radius: 0;
      padding: 0;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
  td {
    width: 100%;
    text-align: center;
    padding: ${Grid._1};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: ${Grid._4} 0;
      text-align: left;
      width: auto;
    }
  }
  .group-person-row__profile-img {
    padding-bottom: ${Grid._1};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding-left: ${Grid._3};
    }
    span,
    img {
      font-size: ${Grid._8};
      position: relative;
      width: ${Grid._8};
      height: ${Grid._8};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        font-size: ${Grid._6};
        width: ${Grid._6};
        height: ${Grid._6};
      }
    }
  }
  .group-person-row__name {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    padding: ${Grid._1} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      text-align: left;
      padding: 0;
    }
  }
  .group-person-row__email {
    padding: ${Grid._1} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: 0;
    }
  }
  .group-person-row._isPending {
    background: ${Color.Gray._075};
    .group-person-row__role {
      color: ${Type.Color.medium};
    }
  }
  .fa-user-circle {
    color: ${Color.Blue._15};
  }
  button {
    width: 100%;
  }
  .group-person-row._isUser {
    .group-person-row__name span {
      color: ${Type.Color.medium};
      margin-left: ${Grid._3};
      background: rgba(0, 0, 0, 0.025);
      padding: ${Grid._1} ${Grid._3};
      border-radius: ${Grid._7};
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: ${Type.Scale._05};
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .group-person-row._isUser {
    order: 0 !important;
  }
  ._isPending .group-person-row__name span {
    color: ${Type.Color.medium};
    font-weight: ${Type.Weight.normal};
    opacity: 0.75;
    display: block;
  }
`;

export default function GroupMembersList({ userEmail, members, clickHandler }) {
  return (
    <Table>
      <tbody>
        {members.map((member, index) => {
          const isUser = member.email === userEmail;
          const isPending = member.emailAddress;
          const rowClassName = 'group-person-row ' + (isUser ? '_isUser ' : ' ') + (isPending ? '_isPending ' : ' ');

          return (
            <tr data-qa-hook="groupDetailResult" key={index} className={rowClassName}>
              <td className="group-person-row__profile-img">
                <ProfileImage src={member.profileImage} />
              </td>
              <td data-qa-hook="groupDetailResultName" className="group-person-row__name">
                {member.displayName}
                {isUser && <span>you</span>}
                {isPending && <span>Not Logged in Yet</span>}
              </td>
              <td data-qa-hook="groupDetailResultEmail" className="group-person-row__email">
                {limitFilter(member.email || member.emailAddress)}
              </td>
              <td className="group-people-list_quick-actions">
                <MediumButton
                  data-qa-hook="groupDetailResultRemove"
                  onClick={() => clickHandler({ show: true, person: member })}
                >
                  Remove
                </MediumButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

GroupMembersList.propTypes = {
  userEmail: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
};
