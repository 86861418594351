import React, { useState, useEffect } from 'react';
import { Grid } from '../StyleGuide';
import { Container, SixOneAspectRatioPlaceholder, ImageDimensionLabel } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import {
  FormFieldMaxCharCountStatus,
  FormFieldContainer,
  Label,
  FormGroupContainer,
  Radio,
  Header,
} from '../components/FormElements';
import { TrainingItem } from './CompilationElements';
import { Buttons, PrimaryButton, Button, SmallRoundedIconButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmationModal from '../components/ConfirmationModal';
import ImageCrop, { ImageCropUtils } from '../components/ImageCrop';
import compilationService, { CatalogComponentStyle, TrainingItemsDisplayStyle } from '../services/compilationService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { OrderingDragContainer, OrderingDragItem, OrderingDragHandle } from '../components/OrderingElements';

const storeFormState = state => sessionStorage.setItem('createCompilation', JSON.stringify(state));
const getStoredFormState = () => JSON.parse(sessionStorage.getItem('createCompilation'));
const clearStoredFormState = () => sessionStorage.removeItem('createCompilation');

const getStoredAddedTraining = () => JSON.parse(localStorage.getItem('compilationTrainingItems')) || [];
const clearStoredAddedTraining = () => localStorage.removeItem('compilationTrainingItems');

const formatTrainingItem = t => ({
  [['Workflow', 'Pathway'].includes(t.trainingType || t._type) ? 'pathwayId' : 'courseId']: t.id,
});

const mergeTrainingItems = (arr1, arr2) => {
  if (!arr1) arr1 = [];
  if (!arr2) arr2 = [];

  return arr1.concat(arr2.filter(x => !arr1.find(y => x.id === y.id)));
};

export default function ManageTrainingCompilation() {
  const [data, setData] = useState({ isLoading: true, isError: false, isSubmitting: false, compilation: {} });
  const [removeModal, setRemoveModal] = useState({ show: false, name: '', id: '' });
  const [form, setForm] = useState();
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Training Compilations', route: '#/manage-catalog/compilations' },
    { name: '' },
  ]);

  const compilationId = windowService.getRoutePart(3);

  const updateFormData = newValues => setForm(form => ({ ...form, ...newValues }));

  const uploadCompilationImage = image =>
    new Promise((resolve, reject) => {
      const bannerFilename = ImageCropUtils.generateFilename();
      if (ImageCropUtils.isCroppedImage(image)) {
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => compilationService.uploadCompilationImage(blob, bannerFilename))
          .then(() => resolve(bannerFilename))
          .catch(reason => reject(reason));
      } else {
        resolve(image);
      }
    });

  const redirectBack = () => windowService.redirectTo(crumbs[crumbs.length - 2].route);

  const submitForm = () => {
    setData({ ...data, isSubmitting: true });

    uploadCompilationImage(form.image)
      .then(bannerFilename => {
        const serviceFn = data.compilation.id
          ? compilationService.updateCompilation
          : compilationService.createCompilation;
        return serviceFn({
          ...form,
          name: form.name.trim(),
          shortDescription: form.shortDescription.trim(),
          longDescription: form.longDescription.trim(),
          image: bannerFilename,
          trainingItems: form.trainingItems.map(formatTrainingItem),
        });
      })
      .then(() => {
        alertService.showOnNextPage(`Training Compilation ${compilationId ? 'Updated' : 'Added'}`);
        redirectBack();
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isSubmitting: false });
      });
  };

  const redirectToAddTraining = () => {
    storeFormState(form);
    windowService.redirectTo(`${window.location.hash}/add-training`);
  };

  const getAddedTraining = () => {
    const addedTrainingItems = getStoredAddedTraining();
    if (addedTrainingItems.length) {
      clearStoredAddedTraining();
      return addedTrainingItems.map(item => ({
        ...item,
        _type: item.trainingType === 'Workflow' ? 'Pathway' : 'Course',
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    let formState = {
      id: '',
      name: '',
      shortDescription: '',
      longDescription: '',
      catalogComponentStyle: CatalogComponentStyle.GRID,
      trainingItemsDisplayStyle: TrainingItemsDisplayStyle.GRID,
      trainingItems: [],
      image: '',
    };

    const storedFormState = getStoredFormState();
    if (storedFormState) {
      const shouldRestoreNewFormState = !compilationId && storedFormState.id === '';
      const shouldRestoreEditFormState = compilationId && storedFormState.id === compilationId;

      if (shouldRestoreNewFormState || shouldRestoreEditFormState) formState = storedFormState;
      clearStoredFormState();

      formState.trainingItems = mergeTrainingItems(formState.trainingItems, getAddedTraining());
    }

    const updateLastCrumbName = (crumbList, newName) => {
      crumbList[crumbList.length - 1] = { name: newName };
      return crumbList;
    };

    if (compilationId) {
      compilationService
        .getCompilation(compilationId)
        .then(compilation => {
          setCrumbs(crumbs => updateLastCrumbName(crumbs, compilation.name));
          setForm(formState.id === compilation.id ? formState : compilation);
          setData(prevData => ({ ...prevData, isLoading: false, compilation: compilation }));
        })
        .catch(error => {
          console.error(error);
          setData(prevData => ({ ...prevData, isLoading: false, isError: true }));
        });
    } else {
      setCrumbs(crumbs => updateLastCrumbName(crumbs, 'New Training Compilation'));
      setForm(formState);
      setData(prevData => ({ ...prevData, isLoading: false }));
    }
  }, []);

  const reorderTrainingItems = ({ oldIndex, newIndex }) => {
    const list = [...form.trainingItems];
    list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
    updateFormData({ trainingItems: list });
  };

  const getInitialBannerImage = (formImage, compilationImage) => {
    if (ImageCropUtils.isCroppedImage(formImage)) {
      return formImage;
    } else if (compilationImage) {
      return compilationService.getCompilationImageUrl(compilationImage);
    } else {
      return '';
    }
  };

  const onBannerImageUpdated = image => {
    if (image === '' || ImageCropUtils.isCroppedImage(image)) {
      updateFormData({ image: image });
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="addEditCompilationView" style={{ margin: `${Grid._6} auto` }}>
          <Header>{compilationId ? 'Edit' : 'New'} Training Compilation</Header>
          <div style={{ margin: '32px 0' }}>
            <Label>Banner Image</Label>
            <SixOneAspectRatioPlaceholder actionable={!form.image}>
              <ImageCrop
                defaultImage={getInitialBannerImage(form.image, data.compilation.image)}
                onUpdate={onBannerImageUpdated}
              />
            </SixOneAspectRatioPlaceholder>
            <ImageDimensionLabel>Recommended: 1440 x 240</ImageDimensionLabel>
          </div>
          <Formik
            enableReinitialize
            initialValues={form}
            validationSchema={Yup.object({
              name: Yup.string().required('A name is required'),
              shortDescription: Yup.string(),
              longDescription: Yup.string(),
            })}
            onSubmit={submitForm}
          >
            {() => (
              <Form>
                <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-40" style={{ margin: '32px 0' }}>
                  <div className="grid-col-span-4">
                    <FormFieldMaxCharCountStatus
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      maxLength={64}
                      onBlur={e => updateFormData({ name: e.target.value })}
                    />
                    <FormFieldMaxCharCountStatus
                      label="Short Description"
                      name="shortDescription"
                      type="text"
                      placeholder="Enter Description"
                      maxLength={124}
                      onBlur={e => updateFormData({ shortDescription: e.target.value })}
                    />
                    <FormFieldMaxCharCountStatus
                      label="Long Description"
                      name="longDescription"
                      as="textarea"
                      rows="10"
                      placeholder="Enter Description"
                      maxLength={512}
                      onBlur={e => updateFormData({ longDescription: e.target.value })}
                    />
                    <FormFieldContainer>
                      <Label>Catalog Display Style</Label>
                      <FormGroupContainer>
                        <p>Choose how this training compilation will display in the training catalog.</p>
                        <div className="controls">
                          <Radio
                            id="catalog-display-grid"
                            name="catalog-display-style"
                            onChange={() => updateFormData({ catalogComponentStyle: CatalogComponentStyle.GRID })}
                            checked={form.catalogComponentStyle === CatalogComponentStyle.GRID}
                          />
                          <label htmlFor="catalog-display-grid">Grid</label>
                          <Radio
                            id="catalog-display-carousel"
                            name="catalog-display-style"
                            onChange={() => updateFormData({ catalogComponentStyle: CatalogComponentStyle.CAROUSEL })}
                            checked={form.catalogComponentStyle === CatalogComponentStyle.CAROUSEL}
                          />
                          <label htmlFor="catalog-display-carousel">Carousel</label>
                        </div>
                      </FormGroupContainer>
                    </FormFieldContainer>
                    <FormFieldContainer>
                      <Label>Training Items Display Style</Label>
                      <FormGroupContainer>
                        <p>Choose how the training items will display when viewing this training compilation.</p>
                        <div className="controls">
                          <Radio
                            id="training-items-display-grid"
                            name="training-items-display-style"
                            onChange={() =>
                              updateFormData({ trainingItemsDisplayStyle: TrainingItemsDisplayStyle.GRID })
                            }
                            checked={form.trainingItemsDisplayStyle === TrainingItemsDisplayStyle.GRID}
                          />
                          <label htmlFor="training-items-display-grid">Grid</label>
                          <Radio
                            id="training-items-display-list"
                            name="training-items-display-style"
                            onChange={() =>
                              updateFormData({ trainingItemsDisplayStyle: TrainingItemsDisplayStyle.LIST })
                            }
                            checked={form.trainingItemsDisplayStyle === TrainingItemsDisplayStyle.LIST}
                          />
                          <label htmlFor="training-items-display-list">List</label>
                        </div>
                      </FormGroupContainer>
                    </FormFieldContainer>
                  </div>
                  <div className="grid-col-span-4">
                    <FormFieldContainer>
                      <Button
                        data-qa-hook="trainingAdd"
                        onClick={redirectToAddTraining}
                        style={{ marginTop: '28px', padding: '12px 20px' }}
                      >
                        Add Training
                      </Button>
                    </FormFieldContainer>
                    <FormFieldContainer>
                      <Label>Training Items</Label>
                      {!form.trainingItems.length ? (
                        <p>No Training Items Added</p>
                      ) : (
                        <OrderingDragContainer
                          className="grid-container grid-col-1"
                          onSortEnd={reorderTrainingItems}
                          useDragHandle
                        >
                          {form.trainingItems.map((item, i) => (
                            <OrderingDragItem data-qa-hook="trainingContainer" key={i} index={i}>
                              <TrainingItem>
                                {form.trainingItems.length > 1 && <OrderingDragHandle />}
                                <div>
                                  <h4 data-qa-hook="trainingName">{item.name}</h4>
                                  <p data-qa-hook="trainingType">{item._type}</p>
                                </div>
                                <SmallRoundedIconButton
                                  data-qa-hook="trainingRemove"
                                  onClick={() => setRemoveModal({ show: true, name: item.name, id: item.id })}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </SmallRoundedIconButton>
                              </TrainingItem>
                            </OrderingDragItem>
                          ))}
                        </OrderingDragContainer>
                      )}
                    </FormFieldContainer>
                  </div>
                </div>
                <Buttons style={{ justifyContent: 'flex-end' }}>
                  <Button data-qa-hook="cancel" onClick={redirectBack}>
                    Cancel
                  </Button>
                  <PrimaryButton
                    data-qa-hook="submit"
                    type="submit"
                    disabled={data.isSubmitting}
                    operating={data.isSubmitting}
                  >
                    {compilationId ? 'Save Compilation' : 'Add Compilation'}
                  </PrimaryButton>
                </Buttons>
              </Form>
            )}
          </Formik>
        </Container>
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Training Item"
          prompt={<span>Are you sure you want to remove this training item?</span>}
          subtext={removeModal.name}
          handleSubmit={() => {
            updateFormData({ trainingItems: form.trainingItems.filter(item => item.id !== removeModal.id) });
            setRemoveModal({ show: false, name: '', id: '' });
          }}
          handleDismiss={() => setRemoveModal({ show: false, name: '', id: '' })}
        />
      )}
    </>
  );
}
